<template>
	<div class="container">
			<div class="box">
				<div class="box-img">
					<img src="../assets/1.png" alt="">
				</div>
				<div class="box-content">
					 <slot name="image_Hover"></slot>
				</div>
			</div>
	</div>
</template>

<script>
	export default{
		props:{
			icon:{
				type:[String],
				default:''
			}
		}
	}
</script>

<style lang="less">
	.box {
		position: relative;
		perspective: 1000px;
	}

	.box:hover{
		.box-content {
			transform: rotateY(0);
		}
		.box-img {
			transform: rotateY(-180deg);
		}
	}
	.box {
		.box-img {
			transform: rotateY(0);
			transition: all 0.50s ease-in-out 0s;
		}
		.box-content {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			padding: 60px 20px;
			text-align: center;
			background: rgba(0, 0, 0, 0.55);
			transform: rotateY(90deg);
			transition: all 0.50s ease-in-out 0s;
		}
		.box-img img {
			width: 100%;
			height: auto;
		}
		.title {
			font-size: 20px;
			color: #fff;
		}

		.description {
			font-size: 14px;
			line-height: 24px;
			color: #fff;
		}

		.title:after,
		.description:after {
			content: "";
			// width: 80%;
			display: block;
		}

		.social-links {
			margin: 0;
			padding: 0;
			list-style: none;
		}
	}
</style>
