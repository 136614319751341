<style lang="less" scoped>
	body {
		background-color: #fff !important;
		z-index: -2;
	}

	a {
		text-decoration: none;
	}

	.advertising {
		height: 722px;
		background-color: #ECEAEA;
		position: relative;

		.advertising_box {
			width: 800px;
			margin: 0 auto;
			font-size: 18px;
			text-align: left;

			.advert_xb {
				width: 100%;
				padding-bottom: 10px;
			}

			>h1 {
				font-size: 38px;
				text-align: center;
				padding: 20px 0;
			}

			h3 {
				font-size: 18px;
				padding: 3px 0;
			}

			span,
			p {
				font-weight: bold;
			}

			span {
				color: #CD6428;
			}

			p {
				color: #666666;
				line-height: 30px;
			}
		}

		.advertising_Top,
		.advertising_Bom {
			width: 80px;
			height: 74px;
			position: absolute;
			z-index: 9;
		}

		.advertising_Top {
			left: 30px;
			top: -20px;
		}

		.advertising_Bom {
			right: 30px;
			bottom: 28px;
		}

		>.advertising_left {
			height: 380px;
			width: 1440px;
			position: absolute;
			left: 50%;
			top: 50%;
			background-color: #fff;
			transform: translate(-50%, -50%);
		}

		.advertising_conten_box {
			width: 100%;
			height: 100%;
			position: relative;
			// overflow: hidden;
			font-size: 12px !important;
		}
	}

	.aboutContest {
		// padding-top: 200px;
		position: relative;

		h5 {
			font-size: 28px;
			color: #666;
		}

		background-color: #fff !important;

		>.aboutContest_flatIcon {
			width: 100%;
			height: 570px;
			position: sticky;
			top: -360px;
			z-index: 1000;
			padding-bottom: 20px;
			background-color: #fff;

			.aboutContest_nav {
				>div:hover {
					background: #932224 !important;
					color: #FFFFFF;
				}
			}

			>.aboutContest_nav {
				position: absolute;
				bottom: 20px;
				width: 1360px;
				left: 50%;
				transform: translateX(-50%);

				.flex_just_disp {

					>div {
						background: rgba(250, 250, 250, 0.65);
						width: 270px;
						height: 68px;
						font-size: 25px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

			}
		}

		>.aboutContest_conten {
			width: 1360px;
			margin: 61px auto;
			text-align: left;

			.el-collapse {
				border-bottom: 0;
			}

			/deep/.el-collapse-item__header {
				background-color: #F5F5F5;
				padding-left: 30px;
				font-size: 25px;
				height: 68px;
				margin-bottom: 16px;
			}

			>p {
				padding: 46px 0 54px 0;
			}

			p {
				font-size: 28px;
				line-height: 60px;
				text-align: left;
				text-indent: 50px;
				color: #666666;
			}

			h2 {
				font-size: 24px;
				font-weight: 500;
				color: #000;
			}

			h3 {
				font-size: 28px;
				position: relative;
				color: #000;
				padding: 30px 0 30px 0;
				z-index: 2;

				>span {
					position: absolute;
					bottom: 38px;
					left: 0;
					display: inline-block;
					width: 106px;
					height: 14px;
					background: #F29723;
					z-index: -1;
					opacity: 0.8;
				}
			}

			.megagame_exp {
				padding-bottom: 10px;

				>.megagame_exp_div {
					color: #666;

					>span:last-child {
						font-size: 20px;
					}

					>span:first-child {
						display: inline-block;
						text-indent: 30px;
						font-size: 28px;
						padding-right: 20px;
					}
				}

				>p {
					padding-top: 14px;
					justify-content: flex-start;
					font-size: 25px;

					>span {
						font-size: 28px;
						color: #666;
						padding: 0;
						margin: 0;
						justify-content: flex-start;
					}

					>span:nth-child(2) {
						text-indent: 0;
						font-size: 18px;
					}
				}

				>.sheet {
					margin-top: 44px;

					>div:nth-child(odd) {
						background-color: #ECEAEA;
					}

					>div {
						height: 68px;

						>p {
							line-height: 38px;
							padding: 15px 0;
							justify-content: center;
							align-items: center;
						}

						>p:last-child {
							padding-left: 83px;
							// width: 100%;
							// text-align: center;
						}

						>p:first-child {
							// padding-left: 99px;
							text-align: center;
							text-indent: 0;
							border-right: 2px solid #DADADA;
							width: 317px;
						}
					}
				}
			}

			>.megagame_title {
				width: 100%;
				height: 88px;
				background-color: #932224;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 36px;

				.elbutton {
					p {
						font-size: 36px !important;
					}
				}
			}
		}
	}

	.aboutContest_conten2 {
		width: 1360px !important;
		padding: 64px 0;

		.awardsSet_box {
			width: 90%;
		}

		>p {
			padding: 40px 0 0 0 !important;
			color: #000 !important;
			font-size: 32px !important;
		}

		>p:first-child {
			padding-top: 0 !important;
		}

		.awardsSet_box:nth-child(4) {
			.awardsSet_conten {
				margin-right: 22px;
			}
		}
	}

	.aboutContest_conten3 {
		justify-content: flex-end;
	}

	.awardsSetting {
		height: 607px;
		background: url(../../assets/bakcsjadop.png);
		background-repeat: no-repeat;
		background-size: cover;

		>.awardsSetting_box {
			>.awards {
				margin-top: 33px;

				>.awards_boder {
					border: 3px solid #932224;
				}

				>.awards_box {
					position: relative;
					text-align: center;
					width: 328px;
					background: rgba(250, 250, 250, 0.81);
					border-radius: 12px;

					.triangle {
						position: absolute;
						border-left: 28px solid transparent;
						border-right: 28px solid transparent;
						border-bottom: 30px solid #FFFFFF;
						width: 0px;
						height: 0px;
						bottom: -60px;
						left: 50%;
						transform: translateX(-50%);
					}

					>.awards_icon {
						width: 43px;
						height: 58px;
						margin: 22px auto 18px auto;
					}

					>p {
						font-size: 20px;
						padding-top: 15px;
					}

					>h5 {
						font-size: 22px;
						color: #000;
					}

					.examine {
						padding-top: 31px;
						padding-bottom: 28px;

						>.examine_box {
							width: 125px;
							height: 36px;
							border-radius: 18px;
							overflow: hidden;
							margin: 0 auto;
							background-color: #932224;
							color: #FFFFFF;
							line-height: 36px;
						}
					}

					>.money {
						justify-content: center;

						>.money_yy {
							width: 140px;
							height: 57px;
							position: relative;
							z-index: 8;

							>div {
								position: absolute;
								width: 133px;
								height: 49px;
							}

							>.money_box {
								background-color: #E0E0E0;
								top: 0;
								left: 0;
							}

							>.money_f2box {
								background-color: #fff;
								bottom: 0;
								right: 0;
								z-index: 5;
								font-size: 28px;
								color: #932224;
								font-weight: 500;
								justify-content: center;
								align-items: center;
								display: flex;
							}
						}
					}
				}
			}

			width: 1360px;
			margin: 0 auto;
			text-align: left;
			color: #666666;

			span {
				color: #F29723;
			}

			>h4 {
				font-size: 28px;
				text-align: center;
				font-weight: 400;
			}

			>h3 {
				font-size: 28px;
				position: relative;
				color: #000;
				color: #333;
				padding: 60px 0 29px 0;
				z-index: 2;

				>span {
					position: absolute;
					bottom: 38px;
					left: 0;
					display: inline-block;
					width: 106px;
					height: 14px;
					background: #F29723;
					z-index: -1;
					opacity: 0.8;
				}
			}
		}
	}

	.dimensionality_box:hover {
		.dimensionality_hover {
			position: absolute;
			width: 100%;
			height: 100%;
			background: #932224;
			color: #fff;
			transition: all .4s linear 0s;
			z-index: 999;
			visibility: visible;

			.dimensionality_conten {
				opacity: 1;
			}
		}
	}

	.dimensionality {
		cursor: pointer;
		/*鼠标悬停变小手*/
		justify-content: center;
		margin-top: 36px;
		position: relative;
		overflow: hidden;

		.dimensionality_box:nth-child(5) {
			margin-right: 0;
		}

		.dimensionality_box:nth-child(10) {
			margin-right: 0;
		}

		.dimensionality_box:nth-child(11) {
			margin-left: 10px;
		}

		.dimensionality_hover {
			position: absolute;
			width: 100%;
			height: 0%;
			bottom: 0;
			transition: all .8s linear 0s;
			display: flex;
			align-items: center;
			justify-content: center;
			visibility: hidden;

			.dimensionality_conten {
				width: 211px;
				display: inline-block;
				opacity: 0;
			}
		}

		.dimensionality_box {
			width: 263px;
			height: 263px;
			background: #FFFFFF;
			position: relative;
			box-shadow: 5px 4px 10px 6px rgba(218, 218, 218, 0.5);
			border: 1px solid #FFFFFF;
			margin: 0 10px 30px 0;

			.dimensionality_icon {
				width: 58px;
				height: 58px;
				margin: 49px auto;
				font-size: 22px;
			}

			text-align: center;

			h6 {
				font-size: 28px;
				padding: 17px;
				position: relative;
				left: 10px;
			}
		}
	}

	.collapse_box {
		margin: 40px 0 40px 0;
	}

	.dataDownload {
		margin-top: 30px;
		margin-bottom: 30px;

		>div {
			font-size: 28px;
			height: 88px;
			background-color: #F5F5F5;
			margin-bottom: 16px;
			padding: 0 79px 0 17px;

			>a {
				color: #932224;
			}
		}
	}

	.contact {
		font-size: 22px;
		padding: 90px 0;

		p {
			color: #676666;
			display: flex;
			align-items: center;
			padding-bottom: 10px;
		}

		h2 {
			display: inline-block;
		}

		.contact_rg {
			font-weight: 400;
			color: #000;
			text-align: center;

			>div:nth-child(1) {
				margin-right: 60px;
			}

			>div {
				span {
					text-indent: 0 !important;
					text-align: center !important;
					width: 100%;
					padding: 33px 0;
					display: inline-block;
				}
			}

			.contact_icon {
				width: 205px;
				height: 195px;
			}
		}
	}

	.schedule {
		height: 430px;
		position: relative;

		.schedule_box_solid {
			width: 100%;
			height: 2px;
			background-color: #ccc;
			position: absolute;
			top: 47%;
		}

		>.schedule_box {
			height: 50%;
			align-items: flex-end;

			>div {
				width: 20%;
				max-width: 20%;
				max-width: 20%;
				text-align: center;
				font-size: 20px;
				position: relative;

				p {
					font-size: 20px;
				}

				h6 {
					padding: 18px 0 43px 0;
					font-size: 20px;
				}

				.schedule_icon {
					width: 136px;
					height: 116px;
					margin: 0 auto 0 auto;
					position: relative;
					z-index: 2;
				}

				.schedule_solid_box {
					.schedule_solid {
						height: 71px;
						width: 3px;
						background-color: #ccc;
						margin: 0 auto;
					}

					.schedule_roid {
						width: 24px;
						height: 24px;
						background: #FFFFFF;
						border: 2px solid #9D9D9D;
						border-radius: 50%;
						margin: 0 auto;
						position: relative;
						z-index: 2;
						background-color: #FFFFFF;
					}
				}

			}
		}

		>.schedule_btom {
			align-items: flex-start;
			position: relative;
			top: -24px;

			h6 {
				padding: 43px 0 18px 0 !important;
			}
		}
	}

	.aboutContest_conten3 {
		width: 1003px;
		justify-content: flex-end;

		>.awardsSet_conten {
			margin-left: 20px;
		}
	}

	.awardsSet {
		width: 1360px;
		background: #FFFFFF;
		justify-content: center;
		align-items: flex-start;
		box-shadow: 6px 6px 7px 4px rgba(204, 204, 204, 0.5);

		.awardsSet_box {
			width: 80%;
			flex-wrap: wrap;
			margin: 0 auto;

			.awardsSet_conten {
				margin-top: 47px;
				position: relative;
				width: 289px;
				height: 145px;
				background: #FFFFFF;
				box-shadow: 2px 2px 7px 5px rgba(220, 220, 220, 0.5);
				border-radius: 14px;
				text-align: center !important;

				.awardsSet_icon {
					width: 89px;
					height: 85px;
					position: absolute;
					top: 0;
					right: 0;
				}

				span {
					position: absolute;
					right: 9px;
					top: 9px;
					font-size: 19px;
					font-weight: bold;
				}

				p {
					text-align: center !important;
					text-indent: 0 !important;
					padding: 20px 0 31px 0;
				}

				h4 {
					font-size: 28px;
				}
			}
		}
	}

	.aboutContest {
		padding-bottom: 70px;
	}

	.aboutContest_nav_Color {
		background: #932224 !important;
		color: #FFFFFF;
	}

	/deep/ .el-collapse-item__wrap {
		border: none;
	}

	.toTopNav {
		width: 100%;
		// height: 200px;
		transition: all 1s linear 0s;
		padding-bottom: 20px;
		background: #ffffff;
		position: sticky;
		top: 100px;
		left: 0;
		z-index: 999;
		right: 0;

		img {
			height: 0;
		}

		.aboutContest_nav {
			>div:hover {
				background: #932224 !important;
				color: #FFFFFF;
			}
		}

		>.aboutContest_nav {
			position: sticky;
			top: 0;
			// bottom: 20px;
			width: 1360px;
			left: 50%;

			transform: translateX(-50%);

			.flex_just_disp {
				>div {
					background: rgba(250, 250, 250, 0.65);
					width: 270px;
					height: 88px;
					font-size: 27px;
					display: flex;
					align-items: center;
					justify-content: center;
					position: sticky;
					top: 0;
				}
			}
		}
	}

	.cursorSBakc:hover {
		background: #932224 !important;
		color: #FFFFFF;
	}

	.examinevisi {
		visibility: hidden;
	}

	.aboutContest_conten {}

	.toTopNav,
	.aboutContest_flatIcon {}

	.advertising_conten {
		z-index: 2;
		width: 1050px;
		height: 520px;
		position: absolute;
		background-color: ;
		left: 50%;
		top: 50%;
		border-radius: 1px;
		transform: translate(-50%, -50%);
		background: url(../../assets/hzzzbak.png);
		background-repeat: no-repeat;
		background-size: cover;
		padding: 10px 20px;
	}

	::v-deep .el-carousel {
		top: 50px;
		width: 800px;
		margin: 0 400px;
		overflow-x: inherit;
		position: relative;
		left: -34px;

		// transform: scale(0.9);
		.el-carousel__item--card {
			width: 1072px;
			height: 542px;
			// transform: translateX(100 px) scale(0.83) !important;
		}

		.el-carousel__arrow--left {
			left: 17rem;
			top: 17rem;
		}

		.el-carousel__arrow--right {
			right: -25rem;
			top: 17rem;
		}

		.el-carousel__mask {
			background-color: transparent;
		}

		// .el-carousel__item:nth-child(2n) {
		// 	background-color: #99a9bf;

		// }

		// .el-carousel__item:nth-child(2n+1) {
		// 	background-color: #d3dce6;
		// }
	}

	.advertising_conten_En {
		font-size: 12px !important;
	}
</style>

<template>
	<!-- 关于大赛 -->
	<div class="aboutContest" ref="box" id="box">
		<div class="icon_image" id="toTop" :class="heightShow?'toTopNav':'aboutContest_flatIcon'">
			<img :src="image">
			<div class="aboutContest_nav">
				<nav class="flex_just_disp">
					<div v-for="(item,index) in $t('inRegard.inRegardNav')" @click="toPinglun(item.url,index)"
						:class="navIndex==index?'aboutContest_nav_Color':''" class="cursorSBakc cursorS" :key="index">
						{{item.title}}
					</div>
				</nav>
			</div>
		</div>

		<div class="aboutContest_conten">
			<!-- <div style="padding-top: 65.10417vw;" v-if="navIndex==0"></div> -->
			<div class="megagame_title" ref="gome0" id="gome0">
				<span>{{$t('inRegard.introduceTitle')}}</span>
			</div>
			<p style="padding-bottom: 0;"><span></span>
				{{$t('inRegard.introduce')}}

			</p>
			<div class="megagame">
				<h3>{{$t('inRegard.competition')}} <span></span></h3>
				<p>{{$t('inRegard.competitionConten')}}</p>
			</div>
			<div class="megagame">
				<h3>{{$t('inRegard.exchange')}}<span></span></h3>
				<p>{{$t('inRegard.exchangeConten')}}</p>
			</div>
			<div class="megagame">
				<h3>{{$t('inRegard.rebirth')}}<span></span></h3>
				<div class="megagame_exp">
					<h2>{{$t('inRegard.theme')}}</h2>
					<p>{{$t('inRegard.themeConten')}}</p>
				</div>
				<div class="megagame_exp">
					<h2>{{$t('inRegard.backdrop')}}</h2>
					<p>{{$t('inRegard.backdropConten')}}</p>
				</div>
				<div class="megagame_exp">
					<h2>{{$t('inRegard.seeting')}}</h2>
					<p>{{$t('inRegard.seetingConten')}}</p>
				</div>
				<div class="megagame_exp">
					<h2 id="sheet">{{$t('inRegard.framework')}}</h2>
					<div class="sheet">
						<div v-for="(item,index) in $t('inRegard.frameworkConten')" :key="index" class="flex_disp">
							<p>{{item.title}}</p>
							<p>{{item.conten}}</p>
						</div>
					</div>
				</div>

			</div>
			<div class="megagame_title" style="margin-top: 1.95313vw;" ref="gome1" id="gome1">
				<span>{{$t('inRegard.regulationTitle')}}</span>
			</div>
			<div class="megagame">
				<h3>{{$t('inRegard.regulationintroduceTitle')}} <span></span></h3>
				<p>{{$t('inRegard.regulationConten')}}<span></span></p>
			</div>
		</div>
		<div class="advertising">
			<!-- <div class="advertising_left"></div> -->
			<el-carousel height="33.85417vw" :autoplay="false" indicator-position="none" type="card" arrow="always"
				:initial-index="initIndex">
				<el-carousel-item v-for="(item,index) in $t('inRegard.respectfullyArr')" :key="index">
					<div class="advertising_conten">
						<div class="advertising_conten_box">
							<div class="advertising_Top icon_image">
								<img src="../../assets/advertisingTop.png">
							</div>
							<div class="advertising_Bom icon_image">
								<img src="../../assets/advertisingBtom.png">
							</div>
							<div class="advertising_box" v-if="index==1||index==2">
								<h1>{{item.title}}</h1>
								<div class="advert_xb flex_disp">
									<p>
										<span>{{$t('inRegard.Freedomgroup')}}</span>
										{{item.conten}}
									</p>
								</div>
								<div class="advert_xb">
									<span>{{item.propositionTitle}}</span>
									<div v-for="(i,idx) in item.propositionArr" :key="idx">
										<h3>{{lang=='en'?i.title2:i.title}}</h3>
										<p>{{lang=='en'?conten2:i.conten}}</p>
									</div>
								</div>
							</div>
							<div class="advertising_box" v-if="index==0">
								<h1>{{item.title}}</h1>
								<p style="text-align: center;">{{item.conten}}</p>
								<div style="font-weight: bold; text-align: center; margin: 0 auto;">
									<p style="padding-top: 2.60417vw; width: 22.78646vw; margin: 0 auto; color: #932224;">
										{{item.label}}</p>
								</div>
							</div>

						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="aboutContest_conten">
			<div class="megagame">
				<h3>{{$t('inRegard.applyTitle')}} <span></span></h3>
				<div class="megagame_exp">
					<h2>{{$t('inRegard.applyTimeTile')}} </h2>
					<p>{{$t('inRegard.timeConten')}} </p>
				</div>
				<div class="megagame_exp">
					<h2>{{$t('inRegard.qualification')}}</h2>
					<p class="flex_disp">
						<span style="text-indent: 0;"></span><span
							style="font-size: 1.56rem;">{{'01'+$t('inRegard.qualificationToe')}}</span>
					</p>
					<p class="flex_disp">
						<span style="text-indent: 0;"></span><span
							style="font-size: 1.56rem;">{{'02'+$t('inRegard.qualificationwet')}}</span>
					</p>
				</div>
				<div class="megagame_exp">
					<h2>{{$t('inRegard.costTitle')}}</h2>
					<p class="flex_disp"><span style="text-indent: 0;">01</span><span
							style="font-size: 1.56rem;">{{$t('inRegard.costToe')}}</span></p>
					<p class="flex_disp"><span style="text-indent: 0;">02</span><span
							style="font-size: 1.56rem;">{{$t('inRegard.costwet')}}</span></p>
				</div>
			</div>
			<h3>{{$t('inRegard.arrangeTitle')}}<span></span></h3>
			<div class="schedule">
				<div class="schedule_box flex_just_disp">
					<div>
						<p>{{$t('inRegard.collect.time')}}</p>
						<h6>{{$t('inRegard.collect.title')}}</h6>
					</div>
					<div class="schedule_icon_box">
						<div class="schedule_icon icon_image">
							<img src="../../assets/schedule2.png">
						</div>
						<div class="schedule_solid_box">
							<div class="schedule_solid"></div>
							<div class="schedule_roid"></div>
						</div>
					</div>
					<div>
						<p>{{$t('inRegard.evaluation.time')}}</p>
						<h6>{{$t('inRegard.evaluation.title')}}</h6>
					</div>
					<div class="schedule_icon_box">
						<div class="schedule_icon icon_image">
							<img src="../../assets/schedule4.png">
						</div>
						<div class="schedule_solid_box">
							<div class="schedule_solid"></div>
							<div class="schedule_roid"></div>
						</div>
					</div>
					<div>
						<p>{{$t('inRegard.administrator.time')}}</p>
						<h6>{{$t('inRegard.administrator.title')}}</h6>
					</div>
				</div>
				<div class="schedule_box_solid"></div>
				<div class="schedule_box schedule_btom flex_just_disp">
					<div class="schedule_icon_box">
						<div class="schedule_solid_box">
							<div class="schedule_roid"></div>
							<div class="schedule_solid"></div>
						</div>
						<div class="schedule_icon icon_image">
							<img src="../../assets/schedule1.png">
						</div>
					</div>
					<div>
						<h6>{{$t('inRegard.preliminary.title')}}</h6>
						<p>{{$t('inRegard.preliminary.time')}}</p>
					</div>
					<div class="schedule_icon_box">
						<div class="schedule_solid_box">
							<div class="schedule_roid"></div>
							<div class="schedule_solid"></div>
						</div>
						<div class="schedule_icon icon_image">
							<img src="../../assets/schedule3.png">
						</div>
					</div>
					<div>
						<h6>{{$t('inRegard.entity.title')}}</h6>
						<p>{{$t('inRegard.entity.time')}}</p>
					</div>
					<div class="schedule_icon_box">
						<div class="schedule_solid_box">
							<div class="schedule_roid"></div>
							<div class="schedule_solid"></div>
						</div>
						<div class="schedule_icon icon_image">
							<img src="../../assets/schedule5.png">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="awardsSetting">
			<div class="awardsSetting_box">
				<h3>{{$t('inRegard.awardsTe.title')}}<span></span></h3>
				<h4>{{$t('inRegard.awardsTe.titleLfe')}}<span>100</span>{{$t('inRegard.awardsTe.awardsF')}}
				</h4>
				<div class="awards flex_just_disp">
					<div class="awards_box cursorS" :class="[awardsIndex==index?'awards_boder':'']"
						@click.stop="awardsIndex=index" v-for="(item,index) in $t('inRegard.awards')" :key="index">
						<div class="awards_icon icon_image">
							<img src="@/assets/prize.png">
						</div>
						<h5>{{item.title}}</h5>
						<p>{{item.ranking}}</p>
						<span
							style="margin: 0 auto ;display: inline-block; width: 2.47396vw; height: undefined; background-color: #ccc;"></span>
						<div class="money flex_disp">
							<span>{{item.moneyName}}</span>
							<div class="money_yy">
								<div class="money_box"></div>
								<div class="money_f2box">{{item.money}}</div>
							</div>
							<span>RMB</span>
						</div>
						<div class="examine">
							<div class="examine_box" :class="[awardsIndex==index?'examinevisi':'']">
								{{$t('inRegard.rectX')}}
							</div>
						</div>
						<div class="triangle" v-show="awardsIndex==index"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="aboutContest_conten" style="margin-top: 0;" v-show="awardsIndex==0">
			<div class="awardsSet flex_disp" style="padding-bottom: 2.60417vw;">
				<div class="awardsSet_box flex_just_disp">
					<div class="awardsSet_conten" v-for="(item,index) in $t('inRegard.awardsce')" :key="index">
						<p style="padding-bottom: 0.5rem;">{{item.title}}</p>
						<h4>{{item.money}}/{{$t('inRegard.name')}}</h4>
						<div class="awardsSet_icon icon_image">
							<img :src="item.icon">
						</div>
						<span>{{item.num}}{{$t('inRegard.name')}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="aboutContest_conten flex_disp" style="justify-content: flex-end;margin-top: 0"
			v-if="awardsIndex==3">
			<div class="awardsSet flex_disp" style="padding-bottom: 2.60417vw;">
				<div class="awardsSet_box flex_just_disp aboutContest_conten3">
					<div class="awardsSet_conten" v-for="(item,index) in micro" :key="index">
						<p style="padding-bottom: 0.5rem;">{{item.title}}</p>
						<h4>{{item.money}}/{{$t('inRegard.name')}}</h4>
						<div class="awardsSet_icon icon_image">
							<img :src="item.icon">
						</div>
						<span>{{item.num}}{{$t('inRegard.name')}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="aboutContest_conten" v-show="awardsIndex==1||awardsIndex==2" style="margin-top: 0;">
			<div class="awardsSet aboutContest_conten2">
				<p>{{$t('inRegard.Freedomgroup')}}</p>
				<div class="awardsSet_box flex_just_disp" style="width: 90%;">
					<div class="awardsSet_conten" v-for="(item,index) in awardsIndex==1?game.freedom:creativity.freedom"
						:key="index">
						<p style="padding-bottom: 0.5rem;">{{item.title}}</p>
						<h4>{{item.money}}/{{$t('inRegard.name')}}</h4>
						<div class="awardsSet_icon icon_image">
							<img :src="item.icon">
						</div>
						<span>{{item.num}}{{$t('inRegard.name')}}</span>
					</div>
				</div>
				<p>{{$t('inRegard.Namedgroups')}}</p>
				<div class="awardsSet_box flex_disp" style="width: 90%;">
					<div class="awardsSet_conten"
						v-for="(item,index) in awardsIndex==1?$t('inRegard.game.life'):$t('inRegard.creativity.life')"
						:key="index">
						<p style="padding-bottom: 0.5rem;">{{item.title}}</p>
						<h4>{{item.money}}/{{$t('inRegard.name')}}</h4>
						<div class="awardsSet_icon icon_image">
							<img :src="item.icon">
						</div>
						<span>{{item.num}}{{$t('inRegard.name')}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="aboutContest_conten">
			<div class="megagame">
				<h3>{{$t('inRegard.dimensionality.tilte')}} <span></span></h3>
				<p>{{$t('inRegard.dimensionality.conten')}} </p>
				<div class="dimensionality flex_disp">
					<div class="dimensionality_box " v-for="(item,index) in $t('inRegard.dimensionalityArr')">
						<div class="dimensionality_hover"><span class="dimensionality_conten">{{item.conten}}</span>
						</div>
						<div class="dimensionality_icon icon_image">
							<img :src="item.icon">
							<!-- <img src="/assets/megagame1.png" > -->
						</div>
						<h6>{{item.text}}<i class="el-icon-arrow-right"></i></h6>
					</div>
				</div>
			</div>
			<h3>{{$t('inRegard.submission.tilte')}} <span></span></h3>
			<div class="collapse_box">
				<el-collapse accordion>
					<el-collapse-item :title="$t('inRegard.DetailsIntroduced')" name="1">
						<div class="megagame_exp" v-for="(item,index) in $t('inRegard.submission.conten')">
							<div class="megagame_exp_div">
								<span>{{index+1>=10?'':'0'}}{{index+1}}</span><span>{{item}}</span>
							</div>
						</div>
						<div style="font-size: 1.30208vw;color: #666;">{{$t('inRegard.submission.query')}}</div>
					</el-collapse-item>
				</el-collapse>
			</div>
			<h3>{{$t('inRegard.palm.title')}} <span></span></h3>
			<div class="collapse_box">
				<el-collapse accordion>
					<el-collapse-item :title="$t('inRegard.DetailsIntroduced')" name="1">
						<div class="megagame_exp">
							<div class="megagame_exp" v-for="(item,index) in $t('inRegard.palm.conten')">
								<div class="megagame_exp_div">
									<span>{{index+1>=10?'':'0'}}{{index+1}}</span><span>{{item}}</span>
								</div>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>

			<div class="megagame_title" ref="gome2" id="gome2">
				<span>{{$t('inRegard.question.title')}}</span>
			</div>
			<div class="collapse_box">
				<el-collapse accordion>
					<el-collapse-item :title="item.title" :name="index"
						v-for="(item,index) in $t('inRegard.question.conten')">
						<div class="megagame_exp">
							<p>{{item.conten}}</p>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
			<div class="megagame_title" id="gome3" ref="gome3">
				<span>{{$t('inRegard.download.title')}}</span>
			</div>
			<div class="dataDownload">
				<div class="dataDownload_box flex_just_disp" v-for="(item,index) in $t('inRegard.download.conten')"
					:key="index">
					<span>{{item.title}}</span>
					<a class="cursorS" target="_Blank"
						:href="index==0?court.court_rule:index==1?court.court_notice:court.recommend_notice">{{$t('newText.msg42')}}</a>
				</div>
			</div>
			<div class="megagame_title" ref="gome4" id="gome4">
				<span>{{$t('inRegard.relation.title')}}</span>
			</div>
			<div class="contact flex_just_disp">
				<div class="contact_left">
					<p v-for="(item,index) in $t('inRegard.relation.conten')" :key="index">
						<span style="padding: 0;text-indent: 0;font-weight: bold;">{{item.title}}</span>
						<span style="padding: 0;text-indent: 0">{{item.ie}}</span>
					</p>
				</div>
				<div class="contact_rg flex_disp">
					<div>
						<div class="contact_icon icon_image">
							<img src="../../assets/zgh.png" alt="">
						</div>
						<span>{{$t('inRegard.qrCode')}}</span>
					</div>
					<div>
						<div class="contact_icon icon_image">
							<img src="../../assets/wbew.png" alt="">
						</div>
						<span>{{$t('inRegard.official')}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="ranking">
			<div class="rankingmuch"></div>
			<div class="ranking_box"></div>
		</div>
		<countdown v-show="rightIconShow"></countdown>
	</div>
</template>

<script>
	import CarouSel from "@/components/carousel.vue";
	import Ebutton from '@/components/ele/elButton.vue';
	import countdown from '@/components/countDown.vue';
	export default {
		components: {
			Ebutton,
			CarouSel,
			countdown
		},
		data() {
			return {
				heightShow: false,
				navIndex: 0,
				languageSet: localStorage.getItem('logInFrom'),
				navList: [{
						title: '大赛介绍',
						url: 'gome0'
					},
					{
						title: '赛制规则',
						url: 'gome1'
					},
					{
						title: '常见问题',
						url: 'gome2'
					},
					{
						title: '资料下载',
						url: 'gome3'
					},
					{
						title: '联系方式',
						url: 'gome4'
					}
				],
				information: [{
						title: '《大赛相关奖项名称及标识使用规则》',
						url: ''
					},
					{
						title: '《参赛须知》',
						url: ''
					},
					{
						title: '《推荐须知》',
						url: ''
					}
				],
				dimensionality: [{
						icon: require('@/assets/megagame1.png'),
						text: '安全性',
						conten: '是否可确保目标用户在使用产品时身体及精神上不受到任何形式的侵害。'
					},
					{
						icon: require('@/assets/megagame2.png'),
						text: '合规性',
						conten: '作品设计是否符合国家法律法规或行业相关要求标准。'
					},
					{
						icon: require('@/assets/megagame3.png'),
						text: '易用性',
						conten: '产品是否可在目标用户不需进行大量学习的情况下快速掌握产品的使用方式并达到或超过预期目的。'
					},
					{
						icon: require('@/assets/megagame4.png'),
						text: '完整性',
						conten: '作品是否能够对产品用途、功能、组成元素进行完整清晰表现。'
					},
					{
						icon: require('@/assets/megagame5.png'),
						text: '创意性',
						conten: '作品是否设计新颖，并具有一定创新元素或创新功能。'
					},
					{
						icon: require('@/assets/megagame6.png'),
						text: '美观性',
						conten: '作品中所表现的产品是否符合社会主流审美观。'
					},
					{
						icon: require('@/assets/megagame7.png'),
						text: '实用性',
						conten: '作品是否和大众生活紧密相关，并具有实际使用价值。'
					},
					{
						icon: require('@/assets/megagame8.png'),
						text: '可行性',
						conten: '作品是否可进行工业化批量生产且具有一定投出产出比。'
					},
					{
						icon: require('@/assets/megagame9.png'),
						text: '文化内涵',
						conten: '作品设计是否包含文化元素或背景，体现文化传承及融汇。'
					},
					{
						icon: require('@/assets/megagame10.png'),
						text: '商业价值',
						conten: '作品投入市场后是否可被接受且产生一定经济收益。'
					},
					{
						icon: require('@/assets/megagame11.png'),
						text: '作品潜力',
						conten: '作品是否有较大的优化或改进提升价值。'
					},
					{
						icon: require('@/assets/megagame12.png'),
						text: '可持续性',
						conten: '产品是否可被长久使用，是否符合环保和节约理念。'
					},
					{
						icon: require('@/assets/megagame13.png'),
						text: '切合主题',
						conten: '产品设计是否与赛道或主题内容存在较高相关性。'
					}
				],
				awardsce: [{
						title: '金奖',
						money: '300,000元',
						icon: require('@/assets/j.png'),
						num: 1,
					},
					{
						title: '银奖',
						money: '100,000元',
						icon: require('@/assets/y.png'),
						num: 2,
					},
					{
						title: '铜奖',
						money: '20,000元',
						icon: require('@/assets/t.png'),
						num: 3,
					},
					{
						title: '最佳产业奖',
						money: '100,000元',
						icon: require('@/assets/q.png'),
						num: 1,
					},
					{
						title: '优质产业奖',
						money: '20,000元',
						icon: require('@/assets/q.png'),
						num: 2,
					},
					{
						title: '优秀奖',
						money: '5,000元',
						icon: require('@/assets/q.png'),
						num: 31,
					}
				],
				micro: [{
						title: '伯乐奖',
						money: '300,000元',
						icon: require('@/assets/q.png'),
						num: 5,
					},
					{
						title: '最佳组织奖',
						money: '100,000元',
						icon: require('@/assets/q.png'),
						num: 10,
					},
				],
				game: {
					freedom: [{
							title: '金奖',
							money: '200,000元',
							icon: require('@/assets/j.png'),
							num: 1,
						},
						{
							title: '银奖',
							money: '100,000元',
							icon: require('@/assets/y.png'),
							num: 2,
						},
						{
							title: '铜奖',
							money: '20,000元',
							icon: require('@/assets/t.png'),
							num: 3,
						},

						{
							title: '优秀奖',
							money: '5,000元',
							icon: require('@/assets/q.png'),
							num: 15,
						}
					],
					life: [{
							title: '最佳创作奖',
							money: '200,000元',
							icon: require('@/assets/j.png'),
							num: 1,
						},
						{
							title: '优质创作奖',
							money: '100,000元',
							icon: require('@/assets/y.png'),
							num: 2,
						},
						{
							title: '优秀奖',
							money: '20,000元',
							icon: require('@/assets/t.png'),
							num: 6,
						}
					]
				},
				creativity: {
					freedom: [{
							title: '金奖',
							money: '100,000元',
							icon: require('@/assets/j.png'),
							num: 1,
						},
						{
							title: '银奖',
							money: '50,000元',
							icon: require('@/assets/y.png'),
							num: 2,
						},
						{
							title: '铜奖',
							money: '10,000元',
							icon: require('@/assets/t.png'),
							num: 3,
						},

						{
							title: '优秀奖',
							money: '5,000元',
							icon: require('@/assets/q.png'),
							num: 15,
						}
					],
					life: [{
							title: '最佳创作奖',
							money: '100,000元',
							icon: require('@/assets/j.png'),
							num: 1,
						},
						{
							title: '优质创作奖',
							money: '50,000元',
							icon: require('@/assets/y.png'),
							num: 2,
						},
						{
							title: '优秀奖',
							money: '5,000元',
							icon: require('@/assets/t.png'),
							num: 6,
						}
					]
				},
				sheet: [{
						title: '主办单位',
						conten: '《环球人物》杂志社、人民文创（北京）文化发展有限公司'
					},
					{
						title: '特别支持单位',
						conten: '中国国际文化交流中心、中共绍兴市上虞区委、绍兴市上虞区人民政府'
					},
					{
						title: '承办单位',
						conten: '环球文创（浙江）文化发展有限公司'
					},
					{
						title: '协办单位',
						conten: '人民文娱、人民教育、腾讯'
					},
					{
						title: '支持单位',
						conten: '人民网、新华网'
					}
				],
				elChangeIndex: 0,
				awards: [{
						title: '人民优创赛道',
						ranking: '奖项40名',
						money: '855,000',
						moneyName: '总奖金',
					},
					{
						title: '城市礼赞赛道',
						ranking: '奖项30名',
						money: '965,000',
						moneyName: '总奖金',
					},
					{
						title: '数字创意赛道',
						ranking: '奖项30名',
						money: '535,000',
						moneyName: '总奖金',
					},
					{
						title: '人民优创赛道',
						ranking: '奖项40名',
						money: '250,000',
						moneyName: '总奖金',
					}
				],
				awardsIndex: -1,
				lang: localStorage.getItem('languageSet') || 'zh',
				initIndex: 0,
				court: {},
				handleShow: false,
				rightIconShow: false,
				image:''
			}
		},
		created() {
			window.scrollTo({
				'top': 0,
				// 'behavior': 'smooth'
			})
		},
		mounted() {
			if (this.$route.query.ef) {
				this.initIndex = parseInt(this.$route.query.initIndex) || 0;
				setTimeout(() => {
					this.toPinglun(this.$route.query.ef, this.$route.query.index);
				}, 200)
			}
			window.addEventListener('scroll', this.handleScroll)
			this.download()
			this.imageList()
		},
		methods: {
			async imageList() {
				try {
					let _this = this
					let params = {
						position_id:1
					}
					let res = await this.$axios.get('ads/getList', {
						params: params
					});
					if (res.code == 1) {
						this.image =  'https://cultural-creation.oss-cn-beijing.aliyuncs.com/'+res.data[0].image
					}
				} catch (error) {
					console.log(error);
				}
			},
			async download() {
				let _this = this
				try {
					let params = {}
					let res = await this.$axios.post('index/agreement', params);
					if (res.code == 1) {
						let data = res.data;
						this.court = data
						this.court.court_rule = data.court_rule
						this.court.court_notice = data.court_notice
						this.court.recommend_notice = data.recommend_notice
					}
				} catch (error) {
					console.log(error);
				}
			},
			handleScroll() {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body
					.scrollTop // 滚动条距离顶部的距离
				// console.log(scrollTop)
				if (scrollTop > 200) {
					this.rightIconShow = true
				} else {
					this.rightIconShow = false
				}
				// if (offsetTop>=400) { 
				// 	this.heightShow = true
				// }
				// if (offsetTop<400) {
				// 	this.heightShow = false
				// }
			},
			elChange(e) {
				this.elChangeIndex = e;
			},
			toPinglun(text, index) {
				this.navIndex = index;
				// #gome3
				this.$nextTick(() => {
					let id;
					console.log(text)
					if (text == 'sheet') {
						id = '#sheet';
					} else {
						id = "#gome" + index;
					}
					let offsetTop = document.querySelector(id).offsetTop // 4116 元素距离顶部的距离
					window.scrollTo({
						'top': offsetTop - 90,
						'behavior': 'smooth'
					})
				})
				// this.$refs[text].scrollIntoView({
				// 	behavior: "smooth", // 平滑过渡
				// 	block: "center" // 上边框与视窗顶部平齐。默认值
				// });
			},
		}
	}
</script>
