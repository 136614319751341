<template>
	<div class="index_box">
		<Header ref="headerH" class="headerH"></Header>
		<router-view></router-view>
		<Bottom></Bottom>
		<div class="rearet_top">
			<el-backtop  :visibility-height="60"> <i class="el-icon-arrow-up" style="color: #FFFFFF;"></i></el-backtop>
		</div>
	</div>
</template>

<script>
	import Hiddsa from '../../components/jssss.vue';
	import Header from '@/components/Header/header.vue';
	import Bottom from '@/components/bottom.vue';
	export default{
		components:{
			Header,
			Bottom,
			Hiddsa
		},
		mounted() {
			let height= this.$refs.headerH.$el.offsetHeight;  
			this.$store.state.navHader=height;
		},
		data(){
			return{
				
			}
		}
	}
</script>

<style lang="less">
	.headerH{
		position: sticky;
		top:0;
		z-index:10000
	}
	body{
		background-color: #F5F5F5;
	}
	.rearet_top{
		position: relative;
		z-index: 9999;
		.el-backtop{
			z-index: 999999999;
			border-radius: 0;
			background-color: #932224;
			right: 10!important;
			bottom: 10px!important;
		}
	}
</style>
