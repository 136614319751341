<template>
	<div class="content">
		<div class="btm_box">
			<div class="nr">
				<div style="font-weight: bold;">{{$t('foot.title')}}</div>
				<div>{{$t('foot.ress')}}</div>
				<div style="font-weight: bold;">{{$t('foot.title1')}}</div>
				<div>{{$t('foot.ress1')}}</div>
				<div>{{$t('foot.phone')}}</div>
				<div>{{$t('foot.email')}}</div>
			</div>
			<div class="text icon_image">
				<img src="../assets/bottomtext.png" />
			</div>
			<div class="foot">
				{{$t('foot.InternetContent')}}
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {

	}
</script>

<style lang="less" scoped>
	.content {
		background: url("../assets/bottom.png") no-repeat;
		background-size: cover;
		height: 311px;
		.btm_box{
			width: 1360px;
			margin: 0 auto;
			display: flex;
			height: 311px;
			align-items: center;
			position: relative;
		}
		.text {
			position: absolute;
			width: 594px;
			height: 149px;
			right: 0;
		}

		.foot {
			width: 100%;
			position: absolute;
			bottom: 10px;
			color: white;
		}

		.nr {
			text-align: left;
			font-size: 18px;
			color: #ffffff;
			// padding: 85px 0;

			div {
				padding: 5px 0;
			}
		}
	}
</style>
