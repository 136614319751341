<template>
	<div class="popup-container">
		<div class="img-flex" :style="'width:'+cing.width+'px;height:'+cing.height+'px;'">
			<!-- <div class="img-flex-img">
				<img src="../assets/competitionLogo.png" >
			</div> -->
			<ul class="flex_disp">
				<!-- <img src="../assets/competitionLogo.png" > -->
				<transition 
				      name="fade"
				      @enter="handleBeforeEnter" // 绑定一个事件
					>
					<li v-for="(item,index) in cing.line*cing.column" :key="index" :style="`
								   width:${cing.width /cing.column/1}px;
								   height:${cing.height/cing.line/1}px;
								   background-position:${-((index%cing.column)*cing.width /cing.column)}px ${-(Math.floor(index/cing.column))*(cing.height/cing.line)}px;
								   position: relative;
								   opacity:${num==cing.animeTime?1:0};
								   background-size: ${cing.width}px ${cing.height}px;
								   left:${num==cing.animeTime?0:Math.ceil(Math.random()*cing.windowWidth*2)-cing.windowWidth}px;
								   top:${num==cing.animeTime?0:Math.ceil(Math.random()*cing.windowHeight*2)- cing.windowHeight}px;
									animation-name: rubberband-horizontal;
									animation-duration: ${cing.animeTime}s;
									animation-timing-function: ease;
									animation-delay: 0s;
									animation-iteration-count: 1;
									animation-direction: normal;
									animation-fill-mode: none;
					
					`"  ></li>
				    </transition>
				
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			cing:{
				type:[Object],
				default:{
					line: 14, //多少行
					column: 20, //多少列
					width: 1680, //显示容器的宽度
					height: 1500,
					windowWidth: 1680,
					windowheight: 1050,
					animeTime: 2,
					img: '' //图片路径
				}
			}	
		},
		data() {
			return {
				num: 0,
				imgStyle: '',
				timer:null,
				num:0
			}
		},
		watch:{
			cing(value){
				console.log(value)
			}
		},
		mounted() {
			let width = this.cing.width / this.cing.column;
			let height = this.cing.height / this.cing.line;
			setTimeout(()=>{
				this.num=this.cing.animeTime;
			},this.cing.animeTime*1000)
		},
		methods:{
			 queryInfo(){
			    //do something
			   },
			handleBeforeEnter(e){
				console.log(e);
			}
		}
	}
</script>

<style lang="less">
	.flex_disp {
		flex-wrap: wrap;
		
	}
	@keyframes rubberband-horizontal {
		100% {
			left: 0;
			top: -2px;
			opacity: 1;
		}
	}
	body {
		font-family: "微软雅黑";
	}
	.img-flex-img{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	@charset "utf-8";
	*{
		margin: 0;
		padding: 0;
	}
	.flex_disp>li{
		list-style: none;
		background-image: url('http://39.103.217.231/uploads/20211208/c2171d811c3920d6d9ef5dca7c1ca61a.jpeg');
	}
	.clearfix{ 
	  *zoom: 1; 
	}
	.clearfix:before, 
	.clearfix:after { 
	  display: table; 
	  line-height: 0; 
	  content: ""; 
	  content: ""; 
	  display: block; 
	  padding-top: 100%;
	}
	.clearfix:after { 
	  clear: both; 
	}
	.popup-container{
		position: fixed;
		z-index: 9999;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.85);
		overflow: hidden;
	}
	.img-flex{
		position: absolute;
	}
	.img-flex>ul{
		font-size: 0;
	}
	.img-flex>.flex_disp>li{
		display: block;
		background-repeat: no-repeat;
		float: left;
		position: relative;
	}
</style>
