<template>
	<div class="dropdown">
		<!-- <div class="btn_box" style="z-index: 999;"> -->
		<!-- 	<el-dropdown>
				
				<el-dropdown-menu size="small" slot="dropdown" placement="top-start">
					<el-dropdown-item><span @click="changeLang('zh')">中文</span></el-dropdown-item>
					<el-dropdown-item><span @click="changeLang('en')">EN</span></el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown> -->
		<!-- <div class="dropbtn"><span></span><i class="el-icon-arrow-down"></i></div>
			<div class="dropdown-content">
			
			</div> -->
		<!-- </div> -->
		<div class="btn_box">
			<span class="el-dropdown-link">
				{{$t('language.name')}}
				<i class="el-icon-arrow-down el-icon--right"></i>
			</span>
			<div class="btn_box_hover">
				<div class="btn_box">
					<div class="btn_icon icon_image">
						<img src="../../assets/coverTop.png">
					</div>
					<div class="btn_box_top">
						<div @click="changeLang('zh')"><span>中文</span></div>
						<div @click="changeLang('en')"><span>EN</span></div>
					</div>
				</div>
			</div>

		</div>
		<div class="header_icon">
			<img src="../../assets/searchIcon.png" @click="toUrl">

			<div class="news_img" v-show="token">
				<div style="margin-right: 1.5625vw;">
					<img src="../../assets/xiaoxi.jpg" slot="reference" style="margin-right: 0;">
					<el-badge is-dot class="item" v-if="iconShow" style="position: relative;top: -0.65104vw;"></el-badge>
				</div>
				<div :class="token?'news_box_hover':''">
					<div class="news_head">
						<div style="float: left;">{{$t("newText.msg79")}}</div>
						<!-- <div class="right">一键清空</div> -->
					</div>
					<div class="news_box" @click="toNews">
						<div v-for="(item,index) in tableData" :key="index" v-if="index<3" class="news_title"
							:class="item.is_read==1?'weidu':'yidu'">
							{{item.no_title}}
						</div>
					</div>
					<div class="news_btn" @click="toNews">{{$t("newText.msg80")}}</div>
				</div>
			</div>

			<img src="../../assets/personage.png" @click="gopersonalCenter('/login')" v-show="!token">
			<div class="menu_img" v-show="token">
				<img src="../../assets/info.png" v-if="image==''" class="info" style="margin-right: 0;">
				<img :src="image" v-else class="info" style="margin-right: 0;">
				<div :class="token?'menu_box_hover':''">
					<div class="btn_box">
						<div class="btn_icon">
							<img src="../../assets/coverTop.png">
						</div>
						<div class="btn_box_top">
							<div @click="gopersonalCenter('/personalCenter')"><span>{{$t("newText.msg81")}}</span></div>
							<div @click="back()"><span>{{$t("newText.msg82")}}</span></div>
						</div>
					</div>
				</div>
				<!-- 	<div >
					
				</div> -->
			</div>


			<!-- <el-dropdown-menu size="small" class="infor_box" slot="dropdown" placement="top-start">
					<el-dropdown-item>
						<p @click="gopersonalCenter('/personalCenter')">个人主页</p>
					</el-dropdown-item>
					<el-dropdown-item>
						<p @click="back()">退出登录</p>
					</el-dropdown-item>
				</el-dropdown-menu> -->
			</el-dropdown>
		</div>
		<div></div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				image: "",
				token: localStorage.getItem('token') || '',
				tableData: [],
				iconShow: false,
				text: ''
			}
		},
		mounted() {
			if (this.token) {
				this.accountInformationObjs();
				this.messageList()
				this.message()
			}
		},
		methods: {
			async message() {
				let _this = this
				try {
					let params = {
						page: 1,
						limit: 1000
					}
					let res = await this.$axios.post('notice/getNum', params);
					if (res.code == 1) {
						let data = res.data
						if (data.new_num > 0) {
							this.iconShow = true
						} else {
							this.iconShow = false
						}
					}
				} catch (error) {
					console.log(error);
				}

			},
			toNews() {
				sessionStorage.setItem('headIndex', localStorage.getItem('USERTYPE') == 2 ? 2 : 4)
				if (this.$route.path != '/personalCenter') {
					this.$router.push({
						name: 'PersonalCenter',
					})
				} else {
					window.location.reload();
				}
			},
			async messageList() {
				let _this = this
				try {
					let params = {
						page: 1,
						limit: 5
					}
					let res = await this.$axios.post('notice/index', params);
					if (res.code == 1) {
						let data = res.data.list
						this.tableData = data
					}
				} catch (error) {
					console.log(error);
				}

			},
			async accountInformationObjs() {
				let _this = this
				try {
					let params = {

					}
					let res = await this.$axios.post('user/index', params);
					// console.log(res)
					if (res.code == 1) {
						let data = res.data;
						this.image = data.user.u_head_img ? data.user.u_head_img : ""
						localStorage.setItem('USERTYPE', data.user.u_user_type);
					}
				} catch (error) {
					console.log(error);
				}

			},
			toUrl() {
				this.$router.push({
					name: "seach"
				})
			},
			goHome() {
				this.$route.push('/')
			},
			gopersonalCenter(url) {
				console.log(url);
				this.$router.push(url);
			},
			back() {
				//卡布达写的
				this.text=localStorage.getItem('languageSet')
				localStorage.clear();
			// localStorage.setItem('languageSet',this.text)
				// sessionStorage.clear();
				localStorage.removeItem('token');
				this.$router.push('/logIn');
			},
			changeLang(text) {
				this.addPv(text)
				// this.text = text;
			},
			async addPv(text) {
				let _this = this;
	
				try {
					// let params = {
					// 	lang: text == 'en' ? 'en-us' : 'zh-cn',
					// 	limit: text == 'en' ? 'v2e.rmwc.com.cn' : 'v2.rmwc.com.cn',
					// }
					// let res = await this.$axios.post('index/addPv', params);
					// if (res.code == 1) {
					// 	localStorage.setItem('languageSet', text);
					// 	window.location.reload()
					// }
					//卡布达写的
					if(text=='zh'){
						window.location.href="https://v2.rmwc.com.cn/home" 
					}
					if(text=='en'){
						window.location.href="https://v2e.rmwc.com.cn/home" 
					}
				} catch (error) {
					console.log(error);
				}
			},
		},
	}
</script>

<style lang="less" scoped>
	/deep/ .el-popover {
		outline-style: none;
	}

	/deep/ .el-popover__reference-wrapper {

		.el-popover__reference {
			box-shadow: none;
			outline-style: none;
		}

	}

	#triangle-up {
		width: 0;
		height: 0;
		// border-left: 20px solid transparent;
		// border-right: 20px solid transparent;
		// border-bottom: 30px solid #FFFFFF;
	}

	.news_btn:hover {
		color: #932224;
	}

	.weidu {
		color: #333333;
	}

	.menu_img:hover {
		.menu_box_hover {
			display: block;
		}
	}

	.infor_box {
		position: relative;
		z-index: 9999;
	}



	.menu_box_hover {
		transform: translateX(-100px) !important;
	}

	.news_img:hover {
		.news_box_hover {
			display: block;

		}
	}

	.news_box_hover {
		display: none;
		margin-top: 0px;
		position: absolute;
		transform: translateX(-448px);
		font-size: 18px;
		cursor: pointer;
		background: #ffffff;
		width: 539px;
		box-shadow: 0px 0px 10px 1px #e6e6e6;

		.news_head {
			height: 75px;
			line-height: 75px;
			padding: 0 25px;
			font-size: 22px;
			color: #000000;

			.right {
				color: #999999;
				float: right;
			}
		}
	}

	.menu_box_hover,
	.btn_box_hover {
		display: none;
		margin-top: 0px;
		position: absolute;
		transform: translateX(-80px);
		width: 145px;
		height: 120px;
		font-size: 18px;
		cursor: pointer;

		.btn_box {
			width: 145px !important;
			height: 120px !important;
			position: relative;

			.btn_icon {
				position: absolute;
				width: 145px !important;
				height: 120px !important;

				img {
					width: 100% !important;
					height: 100%;
				}
			}

			>.btn_box_top {
				position: relative;
				z-index: 9;
				padding-top: 18px;

				>div {
					height: 50px;
					line-height: 50px;
					width: 92%;
					margin: 0 auto;
				}

				>div:hover {
					color: #932224;
				}

				>div:nth-child(1) {
					border-bottom: 1px solid #D3D3D3;
				}
			}

		}

		/*鼠标悬停变小手*/
	}

	.news_btn {
		height: 75px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 22px;
		cursor: pointer;
		color: #000000;
	}

	.news_box {
		border-bottom: #DBDBDB 1px solid;
		border-top: #DBDBDB 1px solid;

		.news_title {
			padding: 13px 25px;
			font-size: 22px;
			cursor: pointer;
		}

		.yidu {
			color: #999999;
		}
	}

	.router-link-active {
		text-decoration: none !important;
		color: #333;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #000;
		font-weight: bold;
		width: 170px;
		font-size: 20px;
	}

	.el-dropdown-menu {}

	.el-icon-arrow-down {
		font-size: 20px;
	}

	.btn_box:hover {
		.btn_box_hover {
			display: block;
		}
	}

	/* 容器 <div> - 需要定位下拉内容 */
	.dropdown {
		.btn_box {
			display: inline-block;
			margin-right: 24px;
		}

		.header_icon {
			display: flex;
			align-items: center;
			cursor: pointer;

			img {
				width: 26px;
				height: 26px;
				margin-right: 24px;
			}

			.info {
				width: 40px;
				height: 40px;
			}
		}

		display: flex;
		align-items: center;
		position: relative;
		// height: 500px;
		font-size: 12px;
		margin-left: 20px;

		/* 在鼠标移上去后显示下拉菜单 */
		.btn_box:hover .dropdown-content {
			transition: all 1s linear 0s;
			display: block;
		}

		/* 下拉按钮样式 */
		.dropbtn {
			>span {
				font-size: 20px;
				font-weight: bold;
				padding-right: 11px;
				display: inline-block;
			}

			border: none;
			height: 15px;
			line-height: 15px;
			text-align: center;
			cursor: pointer;
		}

		/* 下拉内容 (默认隐藏) */
		.dropdown-content {
			display: none;
			position: absolute;
			top: 15px;
			min-width: 100px;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
			background-color: #444444;
			color: #fff;
			z-index: 9999999;

			.dropdown-item {
				padding: 8px 10px;
				text-decoration: none;
				display: block;
				cursor: pointer;
			}

			.dropdown-item:hover {
				background-color: #696969;
			}
		}
	}
</style>
