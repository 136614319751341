<style lang="less" scoped>
	body {
		background: #f5f5f5;
	}
	
	.logIn_box {
		  animation-name: slide-in-elliptic-top-fwd;
		  animation-duration: 1s;
		  animation-timing-function: ease;
		  animation-delay: 0s;
		  animation-iteration-count: 1;
		  animation-direction: normal;
		  animation-fill-mode: none;
		@keyframes slide-in-elliptic-top-fwd {
		  0%{
		    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
		    transform: translateY(-600px) rotateX(-30deg) scale(0);
		    -webkit-transform-origin: 50% 100%;
		    transform-origin: 50% 100%;
		    opacity: 0;
		  }
		  100%{
		    -webkit-transform: translateY(0) rotateX(0) scale(1);
		    transform: translateY(0) rotateX(0) scale(1);
		    -webkit-transform-origin: 50% 1400px;
		    transform-origin: 50% 1400px;
		    opacity: 1;
		  }
		}
		
		  animation-name: rotate-in-bl-anticlock;
		  animation-duration: 1s;
		  animation-timing-function: ease;
		  animation-delay: 0s;
		  animation-iteration-count: 1;
		  animation-direction: normal;
		  animation-fill-mode: none;
		@keyframes rotate-in-bl-anticlock {
		  0%{
		    -webkit-transform: rotate(45deg);
		    transform: rotate(45deg);
		    -webkit-transform-origin: 0 100%;
		    transform-origin: 0 100%;
		    opacity: 0;
		  }
		  100%{
		    -webkit-transform: rotate(0);
		    transform: rotate(0);
		    -webkit-transform-origin: 0 100%;
		    transform-origin: 0 100%;
		    opacity: 1;
		  }
		}
		.el-button {
			width: 100%;
			height: 58px;
			background-color: #932224 !important;
			font-size: 22px;
			border-color: #932224 !important;
		}
	
		.el-button--primary:hover {
			background-color: #932224;
		}
	
		width: 728px;
		margin: 60px auto;
		background-color: #fff;
		padding: 40px 0;
	
		>.logIn_conten {
			width: 494px;
			margin: 0 auto;
			padding-top: 55px;
	
			.login_btn {
				padding-top: 50px;
			}
	
			>.login_rest {
				padding-top: 34px;
	
				>.login_rest_box:hover {
					border-color: #932224;
					color: #932224;
				}
	
				>.login_rest_box {
					font-size: 22px;
					justify-content: center;
					color: #666666;
					width: 237px;
					height: 58px;
					border: 1px solid #979797;
	
					>i {
						padding-left: 10px;
					}
				}
			}
		}
	
		>.logIn_icon {
			width: 167px;
			height: 150px;
			margin: 0 auto;
		}
		/deep/.el-input__icon{
			width: 32px;
			height: 32px;
			font-size: 32px;
			padding-top: 10px;
		}
	}
</style>
<template>
	<div class="logIn">
		<div class="logIn_box">
			<div class="logIn_icon icon_image">
				<img src="@/assets/logo.png" />
			</div>
			<div class="logIn_conten">
				<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<!-- matter.placeholder"  v-model="value" :autocomplete="matter.autocomplete -->
					<el-form-item prop="mailbox">
						<FormInput @inputChange="inputChange" :matter="new $Custom ($t('logIn.toLogin.fillEmail'))"
							:fatherValue.sync="ruleForm.mailbox"  :iValue="ruleForm.mailbox"></FormInput>
					</el-form-item>
					<el-form-item prop="password">
						<FormInput @inputChange="inputChange" :matter="new $Custom ($t('logIn.toLogin.fillPassword'))"
							:fatherValue.sync="ruleForm.password" :showPass="true" :iValue="ruleForm.password"></FormInput>
					</el-form-item>
					<el-form-item>
						<div class="login_btn">
							<el-button type="primary" @click="submitForm('ruleForm')">{{$t('logIn.toLogin.abbr')}}
							</el-button>
						</div>
					</el-form-item>
				</el-form>
				<div class="login_rest flex_just_disp">
					<div class="login_rest_box flex_disp cursorS" @click.stop="goOther('/forget')">
						<span>{{$t('logIn.forgetPassword')}}?</span>
					</div>
					<div class="login_rest_box flex_disp cursorS" @click.stop="goOther('/register')">
						<span>{{$t('logIn.toLogin.registered')}}</span>
						<i class="el-icon-plus"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		isEmail,
		isPasswrd
	} from '@/components/ele/formRules.js'
	import FormInput from '@/components/ele/formInput.vue';
	let validateEmail = (rule, value, callback) => {
		if (!isEmail(value)) {
			callback(new Error(this.$t('newText.msg86')))
		} else {
			callback()
		}
	}
	let validatePassWord = (rule, value, callback) => {
		if (!isPasswrd(value)) {
			callback(new Error(this.$t('newText.msg33')))
		} else {
			callback()
		}
	}

	export default {
		components: {
			FormInput
		},
		data() {
			return {
				input: '',
				ruleForm: {
					mailbox: '',
					password: ''
				},
				rules: {
					mailbox: [{
						required: true,
						message: this.$t('newText.msg83'),
						trigger: 'blur'
					}, {
						validator: validateEmail,
						trigger: 'blur'
					}],
					password:[{required: true, message:this.$t('newText.msg104'), trigger: 'blur'}],
					// {validator: validatePassWord, trigger: 'blur'},{ min: 8,  message: '密码最少8位', trigger: 'blur' }
				}
			}
		},
		watch:{
			ruleForm(value){
				console.log(value);
			}
		},
		mounted() {
			if(localStorage.getItem('logInFrom')){
				let loginFrom=JSON.parse(localStorage.getItem('logInFrom'));
				this.ruleForm=loginFrom.ruleForm;
			}
		},
		methods: {
			async submitForm(value) {
				this.$refs.ruleForm.validate(async valid => {
					if (valid) {
						try {
							let _this = this
							let params = {
								account: _this.ruleForm.mailbox,
								password: _this.ruleForm.password
							}
							let res = await this.$axios.post('user/login', params);
							if (res.code == 1) {
								localStorage.setItem('token', res.data.userinfo.token);
								localStorage.removeItem('logInFrom');
								//卡布达
								localStorage.setItem('languageSet',this.$t('newText.msg136'))
								this.$router.push('personalCenter')
							}
						} catch (error) {
							console.log(error);
						}
					} else {
						return false
					}
				})
			},
			inputChange(){
				console.log(1);
				localStorage.setItem('logInFrom',JSON.stringify(this.$data));
			},
			elValue(val, name) {
				if (name === 'mailbox') {
					this.ruleForm.mailbox = val
				}
				if (name === 'password') {
					this.ruleForm.password = val
				}
			},
			goOther(url) {
				this.$router.push(url);
			}
		}
	}
</script>
