<style lang="less" scoped>
	body {
		background: #f5f5f5;
	}

	.logIn_box {
		animation-name: rotate-in-clock;
		animation-duration: 1s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;

		@keyframes rotate-in-clock {
			0% {
				-webkit-transform: rotate(-45deg);
				transform: rotate(-45deg);
				opacity: 0;
			}

			100% {
				-webkit-transform: rotate(0);
				transform: rotate(0);
				opacity: 1;
			}
		}
	}

	.logIn_box {

		font-size: 22px;

		.el-input__inner:focus {
			transition: all 1.4s linear 0s;
			border-color: #932224 !important;
		}

		>.logIn_title {
			color: #000;
			font-size: 32px;
			font-weight: 400;
		}

		.el-input__inner {
			font-size: 20px;
			height: 58px !important;
			border-radius: 0;
		}

		.el-button {
			width: 100%;
			height: 58px;
			background-color: #932224 !important;
			font-size: 22px;
			border-color: #932224 !important;
		}

		.el-button--primary:hover {
			background-color: #932224;
		}

		width: 728px;
		margin: 60px auto;
		background-color: #fff;
		padding: 40px 0;

		>.logIn_conten {
			width: 494px;
			margin: 0 auto;
			padding-top: 55px;

			>.login_infuseText {
				color: #932224;
				text-align: left;
				padding-top: 49px;
			}

			.login_btn {
				padding-top: 40px;
			}

			>.login_rest {
				padding-top: 34px;

				>.login_rest_box {
					font-size: 22px;
					justify-content: center;
					color: #666666;
					width: 237px;
					height: 58px;
					border: 1px solid #979797;

					>i {
						padding-left: 10px;
					}
				}
			}
		}

		>.logIn_icon {
			width: 167px;
			height: 150px;
			margin: 0 auto;
		}
	}

	.code_box {
		position: relative;

		>span {
			position: absolute;
			font-size: 18px;
			text-align: center;
			border-left: 1px solid #979797;
			height: 21px;
			line-height: 21px;
			right: 0;
			width: 154px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
</style>
<template>
	<div class="logIn">
		<div class="logIn_box">
			<div class="logIn_title"><span>{{$t('logIn.forgetPassword')}}</span></div>
			<div class="logIn_conten">
				<el-form :model="ruleForm" status-icon ref="ruleForm" :rules="rules" class="demo-ruleForm">
					<el-form-item prop="mailbox">
						<FormInput @inputChange="inputChange" :matter="new $Custom ($t('logIn.forget.fillEmail'))"
							:fatherValue.sync="ruleForm.mailbox" :iValue="ruleForm.mailbox"></FormInput>
					</el-form-item>
					<el-form-item prop="checkPass " class="">
						<div class="code_box">
							<FormInput @inputChange="inputChange" :matter="new $Custom ($t('logIn.fillCode'))"
								:fatherValue.sync="ruleForm.fillCode" :iValue="ruleForm.fillCode" ></FormInput>
							<span class="cursorS"
								@click="verification">{{codeShow?countNum+$t('logIn.noGetCode'):$t('logIn.getCode')}}</span>
						</div>
					</el-form-item>
					<el-form-item>
						<div class="login_btn">
							<el-button type="primary" @click="submitForm('ruleForm')">{{$t('logIn.forget.abbr')}}
							</el-button>
						</div>
					</el-form-item>
				</el-form>
				<div class="login_infuseText">
					<span>{{$t('logIn.forget.infuseText')}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import FormInput from '@/components/ele/formInput.vue';
	import {
		isEmail
	} from '@/components/ele/formRules.js'
	let validateEmail = (rule, value, callback) => {
		if (!isEmail(value)) {
			callback(new Error(this.$t('newText.msg86')))
		} else {
			callback()
		}
	}
	export default {
		components: {
			FormInput
		},
		data() {
			return {
				vcode:'',
				ruleForm: {
					mailbox: '',
					fillCode: '',
				},
				rules: {
					mailbox: [{
						required: true,
						message: this.$t('newText.msg83'),
						trigger: 'blur'
					}, {
						validator: validateEmail,
						trigger: 'blur'
					}],
					fillCode: [{
						min: 4,
						max: 4,
						message: this.$t('newText.msg84'),
						trigger: 'blur'
					}],
				},
				intervalBtn: null,
				countNum: 60,
				codeShow: false,
				verShow:false
			}
		},
		mounted() {
			if(localStorage.getItem('forGetFrom')){
				let forGetFrom=JSON.parse(localStorage.getItem('forGetFrom'));
				this.ruleForm=forGetFrom.ruleForm;
			}
		},
		methods: {
			async submitForm(value) {
				
				
				// console.log(this.$refs)
				let params = {
					email: this.ruleForm.mailbox,
					event: 'resetpwd',
					code:this.ruleForm.fillCode
				}
				let res = await this.$axios.post('ems/check', params);
				if(res.code==1){
					this.$refs.ruleForm.validate(async valid => {
					console.log(valid)
					if (valid) {
						try {
							this.$router.push({
								path: 'newPass',
								query: {
									mailbos: this.ruleForm.mailbox,
									fillcode: this.ruleForm.fillCode
								}
							});
						} catch (error) {
							console.log(error);
						}
					} else {
						console.log('error submit!!')
						return false
					}
				})
				}
				
			},
			// 倒计时
			countDown() {
				this.intervalBtn = setInterval(() => {
					if (this.countNum <= 0) {
						this.codeShow = false;
						this.verShow=false;
						clearInterval(this.intervalBtn)
						this.countNum = 60;
					};
					this.countNum--;
				}, 1000);
			},
			inputChange(){
				localStorage.setItem('forGetFrom',JSON.stringify(this.$data));
			},
			async verification(value) {
				if(this.verShow)return;
				this.$refs.ruleForm.validate(async valid => {
					if (valid) {
						try {
							let _this = this
							let params = {
								email: _this.ruleForm.mailbox,
								event: 'resetpwd',
								code:_this.ruleForm.fillCode
							}
							let res = await this.$axios.post('ems/send', params);
							this.verShow=true;
							if (res.code == 1) {
								this.countDown();
								this.codeShow = true;
								this.$message({
									offset: 100 ,message: this.$t('newText.msg85'),
									type: 'success'
								});
								localStorage.removeItem('forGetFrom');
							}
						} catch (error) {
							console.log(error);
						}
					} else {
						console.log('error submit!!') ;
						return false
					}
				})
			}

		}
	}
</script>
