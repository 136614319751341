<style lang="less" scoped>
	.whether_conten{
		width: 100%;
	}
	.bear{
		position: relative;
		.dateTite_box{
			width: 100%;
			position: relative;
			/deep/.el-date-table td.today span {
				color: #932224;
				font-weight: 700;
			}
			.el-year-table td.today .cell
			/deep/.el-date-table td.current:not(.disabled) span{
				background-color: #932224 !important;
			}
			/deep/.el-year-table td .cell:hover{
				color: #932224!important;
			}
		}
		
		/deep/.el-date-editor.el-input, .el-date-editor.el-input__inner {
		    width: 100%;
		}
		/deep/.el-input__inner:focus {
			transition: all 1.4s linear 0s;
			border-color: #932224 !important;
		}
		/deep/.el-input__prefix .el-input__icon{
			display: none;
		}
		/deep/.el-input--prefix .el-input__inner {
			padding-left: 20px;
		}
		
		.el_Option {
			font-size: 22px !important;
		}
		
		/deep/.el-select-dropdown__item.selected {
			color: #932224 !important;
		}
		
		/deep/.el-select-dropdown__item.hover,
		/deep/.el-select-dropdown__item:hover {
			color: #932224 !important;
		}
		
		/deep/.el-input__inner {
			font-size: 20px;
			height: 58px !important;
			border-radius: 0;
		}
		
		/deep/.el-form-item__label {
			font-size: 22px !important;
			text-align: right;
			line-height: 58px;
			font-weight: 420;
		}
		/deep/.el-input {
			height: 55px;
		}
		>.bear_Add{
			width: 58px;
			height: 58px;
			position: absolute;
			right: 0;
			top: 29px;
		}
		>.bear_box{
			.basic_box{
				padding: 39px 117px 20px 0;
				background-color: #fff;
				margin-bottom: 20px;
				position: relative;
				>.baar_delete{
					position: absolute;
					width: 56px;
					height: 56px;
					right: -20px;
					top: -20px;
				}
			}
		}
		
	}
	.production {

		>.material,
		>.situation,>.bear{
			>h3 {
				text-align: left;
				font-size: 28px;
				padding: 35px 0;
			}

			padding-bottom:55px;
		}

		// 作品情况
		>.situation {
			>.situation_box {
				background-color: #fff;
				padding: 0 54px;
				>.market,>.copyright{
					text-align: left;
					>h3{
						padding-top: 40px;
						font-size: 28px;
						font-weight: 400;
					}
					>.market_conten{
						padding: 50px 0;
					}
				}
				>h3 {
					font-size: 28px;
				}
			}
		}

		// 作品上传
		>.material,
		>.situation {
			>.material_box {
				background-color: #fff;
				font-size: 22px;
				padding: 10px 42px 49px 42px;

				>.material_conten {
					padding-top: 39px;

					>.hint {
						font-size: 18px;
						color: #9D9D9D;
						text-align: left;
						padding-top: 15px;
						padding-left: 200px;
					}

					>div {
						position: relative;
						>.download {
							width: 128px;
							height: 58px;
							line-height: 58px;
							background: #932224;
							color: #fff;
							position: absolute;
							top: 0;
							right: 0;
						}

						>.material_l {
							text-align: right;
							width: 162px;
							height: 100%;
							position: relative;

							>span {
								display: inline-block;
							}

							>p {
								font-size: 18px;
								color: #9D9D9D;
								position: absolute;
							}
						}

						>.material_input {
							border: 1px solid #ccc;
							width: 632px;
							height: 58px;
							margin: 0 20px;
							padding: 0 23px;
							color: #9D9D9D;
							position: relative;
							>.material_icon {
								width: 44px;
								height: 44px;
							}
						}
					}
				}
			}
		}
	}
	.photograph:hover{
		.preview{
			visibility: visible;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			padding: 60px 20px;
			text-align: center;
			background: rgba(0, 0, 0, 0.55);
			transform: rotateX(180deg);
			transition: all 0.50s ease-in-out 0s;
			>.preview_icon{
				opacity: 1;
			}
		}
	}
	.photograph{
		width: 274px;
		height: 194px;
		margin-left: 15px;
		position: relative;
		overflow: initial !important;
		background: #F5F5F5;
		>.preview{
			transform: rotateX(0);
			justify-content: center;
			align-items: center;
			visibility: hidden;
			position: absolute;
			pointer-events: none;
			>.preview_icon{
				width: 34px;
				height: 24px;
				opacity: 0;
			}
 		}
		>.avatar{
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}
		.photograph_conten{
			width: 194px;
			margin: 0 auto;
			font-size: 12px;
			>.photograph_img{
				width: 90px;
				height: 90px;
				margin: 35px auto 17px auto;
			}
		}
		>.photographDele{
			width: 45px;
			height: 45px;
			position: absolute;
			top: -20px;
			right: -20px;
			z-index: 9;
		}
	}
	.eb_box{
		width: 334px;
		height: 66px;
	}
	.eb_conten{
		width: 100%;
		align-items: center;
		justify-content: center;
		margin-top: 74px;
		>div{
			margin-right: 20px;
			width: 334px !important;
			height: 66px !important;
		}
	}
</style>
<template>
	<div class="production">
		<div class="material">
			<h3>上传资料</h3>
			<div class="material_box">
				<div class="material_conten">
					<div class="flex_disp floatingBoder_hovr">
						<div class="material_l">
							<span>{{$t('forinformation.data.pictureTitle')}}<span class="sign_app_text">*</span></span>
							<p>{{$t('forinformation.data.watch')}}</p>
						</div>
						<div class="material_input floatingBoder_hovr flex_just_disp" v-if="worksIcon.length===0">
							<UpLoading @success="ImgSuccess"></UpLoading>
							<span>{{$t('forinformation.data.pictureExplain')}}</span>
							<div class="material_icon icon_image">
								<img src="@/assets/productionicon1.png">
							</div>
						</div>
						<div v-if="worksIcon.length>0 " class="flex_disp">
							<div class="photograph icon_image" v-for="(item,index) in worksIcon" :key="index">
								<el-image 
								    style="width: 100%; height: 100%"
								    :src="item" 
								    :preview-src-list="worksIcon" fit="scale-down">
								  </el-image>
								<div class="photographDele icon_image" @click.stop="worksIcon.splice(index,1)">
									<img src="../../assets/imgdele.png" >
								</div>
								<div class="preview flex_disp">
									<div class="preview_icon icon_image">
										<img src="../../assets/编组@2x.png" >
									</div>
								</div>
							</div>
							<div class="photograph icon_image" v-if="worksIcon.length<3">
								<img src="../../assets/imgAdd.png" >
								<UpLoading @success="ImgSuccess"></UpLoading>
							</div>
						</div>
						<div class="download">{{$t('forinformation.data.template')}}</div>
					</div>
					<p class="hint" v-if="worksIcon.length===0">{{$t('forinformation.data.pictureHint')}}</p>
				</div>
				<div class="material_conten">
					<div class="flex_disp ">
						<div class="material_l">
							<span>{{$t('forinformation.data.videoTitle')}}</span>
						</div>
						<div class="material_input floatingBoder_hovr flex_just_disp" v-if="!videoSrc">
							<UpLoading @success="videoChange" name="video"></UpLoading>
							<span>{{$t('forinformation.data.videoExplain')}}</span>
							<div class="material_icon icon_image">
								<img src="@/assets/productionicon2.png">
							</div>
						</div>
						<div class="photograph" v-if="videoSrc">
							<video v-if="videoSrc!=''" :src="videoSrc"
								class="avatar video-avatar" controls="controls">
								您的浏览器不支持视频播放
							</video>
							<div class="photographDele icon_image" @click.stop="videoSrc=''">
								<img src="../../assets/imgdele.png" >
							</div>
						</div>
					</div>
					<p class="hint" v-show="!videoSrc">{{$t('forinformation.data.videoHint')}}</p>
				</div>
				<div class="material_conten ">
					<div class="flex_disp">
						<div class="material_l">
							<span>{{$t('forinformation.data.documentTitle')}}</span>
						</div>
						<div class="material_input floatingBoder_hovr flex_just_disp">
							<span>{{$t('forinformation.data.documentExplain')}}</span>
							<div class="material_icon icon_image">
								<img src="@/assets/productionicon3.png">
							</div>
							<UpLoading @success="getpptHttp" name="PPT"></UpLoading>
						</div>
						<div v-if="pptList.length>0 " class="flex_disp">
							<div class="photograph icon_image" v-for="(item,index) in pptList" :key="index">
								<div class="photograph_conten">
									<div class="photograph_img icon_image">
										<img src="../../assets/ppt.png" >
									</div>
									<p class="text_2_size">{{item.name}}</p>
								</div>
								<div class="photographDele icon_image" @click.stop="pptList.splice(index,1)">
									<img src="../../assets/imgdele.png" >
								</div>
							</div>
							<div class="photograph icon_image" v-if="pptList.length<3">
								<img src="../../assets/imgAdd.png" >
								<UpLoading @success="getpptHttp" name="PPT"></UpLoading>
							</div>
							<div class="preview flex_disp">
								<div class="preview_icon icon_image">
									<img src="../../assets/编组@2x.png" >
								</div>
							</div>
						</div>
					</div>
					<p class="hint">{{$t('forinformation.data.documentHint')}}</p>
				</div>
				<div class="material_conten">
					<div class="flex_disp">
						<div class="material_l">
							<span>{{$t('forinformation.data.modelingTitle')}}</span>
						</div>
						<div class="material_input floatingBoder_hovr flex_just_disp" v-if="wordList.length==0">
							<span>{{$t('forinformation.data.modelingExplain')}}</span>
							<div class="material_icon icon_image">
								<img src="@/assets/productionicon3.png">
							</div>
							<UpLoading @success="getWordHttp" name="WORD"></UpLoading>
						</div>
						<div v-if="wordList.length>0 " class="flex_disp">
							<div class="photograph icon_image" v-for="(item,index) in wordList" :key="index">
								<div class="photograph_conten">
									<div class="photograph_img icon_image">
										<img src="../../assets/word.png" >
									</div>
									<p class="text_2_size">{{item.name}}</p>
								</div>
								<div class="photographDele icon_image" @click.stop="wordList.splice(index,1)">
									<img src="../../assets/imgdele.png" >
								</div>
								<div class="preview flex_disp">
									<div class="preview_icon icon_image">
										<img src="../../assets/编组@2x.png" >
									</div>
								</div>
							</div>
							<div class="photograph icon_image" v-if="wordList.length<3">
								<img src="../../assets/imgAdd.png" >
								<UpLoading @success="getWordHttp" name="WORD"></UpLoading>
							</div>
						</div>
					</div>
					<p class="hint">{{$t('forinformation.data.modelingHint')}}</p>
				</div>
			</div>
		</div>
		<!-- 作品情况 -->
		<div class="situation">
			<h3>作品情况</h3>
			<div class="situation_box">
				<!-- 销售情况 -->
				<div class="market">
					<h3>作品上市销售情况<span class="sign_app_text">*</span></h3>
					<div class="whether_conten">
						<Whether :whether="$t('forinformation.data.marketArr')" @whetherClick="whetherClick" :radio="whetherIndex"></Whether>
					</div>
				</div>
				<div class="copyright">
					<h3 style="padding-top: 0;">{{$t('forinformation.data.copyrightName')}}<span class="sign_app_text">*</span> </h3>
					<Whether :whether="$t('forinformation.data.copyrightArr')" @whetherClick="copyrightClick" :radio="copyrightIndex"></Whether>
				</div>
			</div>
		</div>
		<div class="bear">
			<h3>作品获奖情况(选填)</h3>
			<div class="bear_Add icon_image" @click.stop="bearAdd($t('forinformation.bear')[0])">
				<img src="@/assets/productionAdd.png" >
			</div>
			<div class="bear_box">
				<div class="basic_box" v-for="(item,index) in $t('forinformation.bear')" :key="index">
					<div class="baar_delete icon_image" v-if="index!=0" @click.tap="bearDele(index)">
						<img src="@/assets/productionDele.png" >
					</div>
					<el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
						<el-form-item>
							<el-col :span="12">
								<el-form-item label-width="8.98438vw" prop="region"
									:label="item.bearName">
									<FormInput :matter="new $Custom(item.nameInput)"></FormInput>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label-width="8.98438vw" prop="region"
									:label="item.bearX">
									<FormInput :matter="new $Custom(item.xInput)"></FormInput>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item>
							<el-col :span="12">
							      <el-form-item label-width="8.98438vw" prop="date1" :label="item.bearTime">
									  <div class="dateTite_box">
										  <el-date-picker  style="width:100%"  type="date" :placeholder="item.timeInput" v-model="dataTime"></el-date-picker>
									  </div>
							      </el-form-item>
							</el-col>
						</el-form-item>
					</el-form>
				</div>
			</div>
		
		</div>
		<div class="eb_conten flex_disp">
			<div class="eb_conten_box">
				<!-- 保存 -->
				<Ebutton @btnTap="lastStep" text="上一步"></Ebutton>
			</div>
			<div class="eb_conten_box">
				<!-- 保存 -->
				<Ebutton @btnTap="save"></Ebutton>
			</div>
			<div class="eb_conten_box">
				<!-- 保存 -->
				<Ebutton @btnTap="nextStep" text="下一步"></Ebutton>
			</div>
		</div>
	</div>
</template>

<script>
	import Ebutton from '@/components/ele/elButton.vue';
	import Whether from '@/components/whetherRadio.vue';
	import FormInput from '@/components/ele/formInput.vue';
	import UpLoading from '@/components/ele/uploading.vue';
	export default{
		components:{
			Whether,
			UpLoading,
			FormInput,
			Ebutton
		},
		data(){
			return{
				whetherIndex:0,
				copyrightIndex:0,
				ruleForm:{},
				dataTime:'',
				worksIcon:[],
				videoSrc:'',
				pptList:[],
				wordList:[]
			}
		},
		methods:{
			whetherClick(index){
				this.whetherIndex=index;
				console.log(this.radioIndex)
			},
			copyrightClick(index){
				this.copyrightIndex=index;
			},
			bearAdd(item){
				if(this.$t('forinformation.bear').length>10)return;
				this.$t('forinformation.bear').push(item);
			},
			bearDele(index){
				this.$t('forinformation.bear').splice(index,1);
			},
			getpptHttp(url,name){
				this.pptList.push({url,name});
			},
			getWordHttp(url,name){
				this.wordList.push({url,name})
				console.log(this.wordList);
			},
			ImgSuccess(e){
				this.worksIcon.push(e);
			},
			videoChange(e){
				this.videoSrc=e;
			},
			save(){
				
			},
			nextStep(){
				
			},
			lastStep(){
				this.$router.push('Foruploading');
			}
		}
	}
</script>
