<template>
	<div>
		<Header :show="false"></Header>
		<div class="agreement">
			<div class="return_icon cursorS flex_disp" @click.stop="goBack">
				<i class="el-icon-arrow-left"></i>
			</div>
			<div v-html="index==1?conten.agreement:conten.referral"></div>
		</div>
	</div>
</template>

<script>
	import Header from '../components/Header/header.vue';
	export default{
		components:{
			Header
		},
		data(){
			return{
				conten:[],
				index:this.$route.query.index
			}
		},
		mounted() {
			this.agreement()
		},
		methods:{
			goBack() {
				console.log(this.$route.name);
				if (window.history.length <= 1||this.$route.name=='Forinformation') {
					this.$router.push({
						path: '/'
					})
					return false
				} else {
					this.$router.go(-1)
				}
			},
			async agreement(){
				try{
					let res = await this.$axios.get('index/agreement');
					this.conten=res.data;
				}catch(e){
					//TODO handle the exception
				}
			}
		}
	}
</script>

<style scoped lang="less">
	body{
		background-color: #FFFFFF!important;
		position: relative;
	}
	.agreement{
		margin-top: 30px;
		padding: 80px 0;
		width: 1360px;
		margin: 0 auto;
		font-size: 22px !important;
		position: relative;
		/deep/ div,span{
			font-size: 18px !important;
			text-align: left;
			text-indent: 0;
		}
		span{
			color: #666666;
		}
	}
	.return_icon {
		position: fixed;
		left: 117px;
		background: #932224;
		color: #FFFFFF;
		font-size: 20px;
		border-radius: 50%;
		top: 177px;
		width: 40px;
		height: 40px;
		z-index: 9;
		justify-content: center;
		font-weight: bold;
	}
</style>
