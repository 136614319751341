<style lang="less">
	body {
		background: #f5f5f5;
	}

	.logIn_box {
		.el-input__icon {
			width: 32px;
			height: 32px;
			font-size: 32px;
			padding-top: 10px;
		}

		animation-name: rotate-in-anticlock;
		animation-duration: 1s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;

		@keyframes rotate-in-anticlock {
			0% {
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				opacity: 0;
			}

			100% {
				-webkit-transform: rotate(0);
				transform: rotate(0);
				opacity: 1;
			}
		}

		color: #9D9D9D;

		.el-input__inner:focus {
			transition: all 1.4s linear 0s;
			border-color: #932224 !important;
		}

		.el-input__inner {
			font-size: 20px;
			height: 58px !important;
			border-radius: 0;
		}

		.el-button {
			width: 100%;
			height: 58px;
			background-color: #932224 !important;
			font-size: 22px;
			border-color: #932224 !important;
		}

		.el-button--primary:hover {
			background-color: #932224;
		}

		width: 728px;
		margin: 60px auto;
		background-color: #fff;
		padding: 59px 0;

		>.logIn_conten {
			width: 494px;
			margin: 0 auto;

			.login_btn {
				padding-top: 50px;
			}

			>.login_rest {
				width: 494px;

				>.login_rest_box {
					font-size: 22px;
					justify-content: center;
					color: #666666;
					width: 100%;
					height: 58px;
					border: 1px solid #979797;
				}
			}
		}

		>.logIn_icon {
			width: 167px;
			height: 150px;
			margin: 0 auto;
		}
	}

	.entry_nav {
		width: 80%;
		margin: 0 auto;
		font-size: 38px;
		text-align: center;
		padding-bottom: 41px;

		>div {
			width: 147px;

			>.categoryColor {
				color: #932224;
				transition: all 0.4s linear 0s;
			}

			>.entry_solid {
				width: 100%;
				height: 3px;
				background-color: #fff;
				margin: 13px auto;
			}

			>.categoryBack {
				width: 100%;
				background-color: #932224;
				transition: all 0.4s linear 0s;
			}

			>.categoryOn {
				width: 0;
				background-color: #fff;
				transition: all 0.4s linear 0s;
			}
		}
	}

	.code_box {
		position: relative;

		>span {
			position: absolute;
			font-size: 18px;
			text-align: center;
			border-left: 1px solid #979797;
			height: 21px;
			line-height: 21px;
			right: 0;
			width: 154px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.regulation {
		font-size: 18px;
		padding-top: 30px;

		>.regulation_icon {
			width: 22px;
			height: 22px;
			margin-right: 11px;
		}

		>.regulation_conten {
			color: #666666;

			>span {
				color: #932224;
			}
		}
	}

	.regulation_iconBoder {
		border: 1px solid #ccc;
	}
	.setFonte{
		font-size: 16px;
	}
	.entry_nav_jsis{
		justify-content: center!important;
	}
</style>

<template>
	<div class="logIn">
		<div class="logIn_box">
			<div class="logIn_conten">
				<div class="entry_nav flex_just_disp" :class="[$t('logIn.register.category').length==1?'entry_nav_jsis':'']">
					<div v-for="(item,index) in $t('logIn.register.category')" :key="index"
						@click="categoryIndex=index">
						<p class="cursorS"  :class="[categoryIndex==index?'categoryColor':'']">{{item}}</p>
						<p class="entry_solid cursorS" :class="[categoryIndex==index?'categoryBack':'categoryOn']"></p>
					</div>
				</div>
				<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item prop="mailbox">
						<FormInput  @inputChange="inputChange" :matter="new $Custom ($t('logIn.toLogin.fillEmail'))"
							:fatherValue.sync="ruleForm.mailbox" :iValue="ruleForm.mailbox"></FormInput>
					</el-form-item>
					<el-form-item prop="checkPass">
						<div class="code_box">
							<FormInput @inputChange="inputChange"  :matter="new $Custom ($t('logIn.fillCode'))"
								:fatherValue.sync="ruleForm.verification" :iValue="ruleForm.verification"></FormInput>
							<span class="cursorS" @click="verification">{{codeShow?countNum+$t('logIn.noGetCode'):$t('logIn.getCode')}}</span>
						</div>
					</el-form-item>
					<el-form-item prop="password" style>
						<FormInput @inputChange="inputChange"  :matter="new $Custom ($t('logIn.setpassword.setPassword'))"
							:fatherValue.sync="ruleForm.password" :showPass="true" :iValue="ruleForm.password">
						</FormInput>
						<p class="setFonte">{{$t('logIn.setpassword.infuse')}}</p>
					</el-form-item>
					<el-form-item prop="isPassword">
						<FormInput @inputChange="inputChange"  :matter="new $Custom ($t('logIn.twoPassword'))"
							:fatherValue.sync="ruleForm.isPassword" :showPass="true" :iValue="ruleForm.isPassword">
						</FormInput>
					</el-form-item>
					<el-form-item>
						<div class="login_btn">
							<el-button type="primary" @click="submitForm('ruleForm')">{{$t('logIn.signAway')}}
							</el-button>
						</div>
					</el-form-item>
				</el-form>
				<div class="login_rest cursorS">
					<div class="login_rest_box flex_disp" @click.stop="goOther('/login')">
						<span>{{$t('logIn.already')}}?</span>
					</div>
				</div>
				
				<div class="regulation flex_disp">
					<div class="icon_image regulation_icon cursorS" @click="regulationicon=!regulationicon"
						:class="regulationicon==false?'regulation_iconBoder':''">
						<img src="@/assets/regulationicon.png" v-show="regulationicon">
					</div>
					<div class="regulation_conten" v-show="categoryIndex==0">
						{{$t('newText.msg90')}}
						<span class="cursorS" @click="cursorSfn1()">{{$t('newText.msg91')}}</span>
						{{$t('newText.msg101')}}
						<span class="cursorS" @click="cursorSfn()">{{$t('newText.msg92')}}</span>
					</div>
					
					<div class="regulation_conten" v-show="categoryIndex==1">
						{{$t('newText.msg90')}}
						<span class="cursorS" @click="cursorSfn()">{{$t('newText.msg92')}}</span>
					</div>
				
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import FormInput from '@/components/ele/formInput.vue';
	import {
		isEmail,
		isPasswrd
	} from '@/components/ele/formRules.js'
	export default {
		components: {
			FormInput
		},
		data() {
			let validateEmail = (rule, value, callback) => {
				if (!isEmail(value)) {
					callback(new Error(this.$t('newText.msg86')))
				} else {
					callback()
				}
			}
			let validatePassWord = (rule, value, callback) => {
				if (!isPasswrd(value)) {
					callback(new Error(this.$t('newText.msg33')))
				} else {
					callback()
				}
			}
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error(this.$t('newText.msg34')));
				} else if (value !== this.ruleForm.password) {
					callback(new Error(this.$t('newText.msg35')));
				} else {
					callback();
				}
			};
			return {
				codeShow: false,
				countNum: 60,
				input: '',
				regulationicon: false,
				ruleForm: {
					mailbox: '',
					password: '',
					verification: "",
					isPassword: ''
				},
				rules: {
					mailbox: [{
						required: true,
						message: this.$t('newText.msg83'),
						trigger: 'blur'
					}, {
						validator: validateEmail,
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: this.$t('newText.msg37'),
						trigger: 'blur'
					}, {
						validator: validatePassWord,
						trigger: 'blur'
					}, {
						min: 8,
						message: this.$t('newText.msg38'),
						trigger: 'blur'
					}],
					verification: [{
						min: 4,
						max: 4,
						message: this.$t('newText.msg84'),
						trigger: 'blur'
					}],
					isPassword: [{
						validator: validatePass,
						trigger: 'blur'
					}]
				},
				categoryIndex: 0,
				intervalBtn:null,
				verShow:false,
				parent_id:0
			}
		},
		mounted() {
			//卡布达写的
			if(localStorage.getItem('num')==1){
				this.categoryIndex=1
			}
			if(localStorage.getItem('num')==0){
				this.categoryIndex=0
			}
			//
			console.log(localStorage.getItem('regFrom'))
			if(localStorage.getItem('regFrom')){
				let regFrom = JSON.parse(localStorage.getItem('regFrom'));
				this.ruleForm=regFrom.ruleForm;
			}
			if(this.$route.query.id){
				this.parent_id=this.$route.query.id;
				// this.$t('language.list')
				this.$t('logIn.register.category').splice(1,1);
			}else{
				// this.$t('logIn.register.category')=['本届参赛作品', '往届参赛作品'];
			}
			 // <!-- v-if="$route.query.id&&index!=1" -->
		},
		methods: {
			//卡布达写的
			cursorSfn1(){
				localStorage.setItem('num',this.categoryIndex)
				this.$router.push({path:'Agreement',query:{index:1}})
			},
			cursorSfn(){
				localStorage.setItem('num',this.categoryIndex)
				this.$router.push({path:'Agreement',query:{index:2}})
			},
			//
			inputChange(){
				localStorage.setItem('regFrom',JSON.stringify(this.$data))
			},
			async submitForm(value) {
				this.$refs.ruleForm.validate(async valid => {
					if (valid) {
						if (!this.regulationicon) {
							this.$message.error({
								offset: 100,
								message:this.$t('newText.msg135')
							});
							return
						}
						try {
							let _this = this
							let params = {
								email: _this.ruleForm.mailbox,
								password: _this.ruleForm.password,
								user_type: _this.categoryIndex == 0 ? 1 : 2,
								source: 0,//打包修改 //0是中文 1是英文
								code: parseInt(_this.ruleForm.verification),
								parent_id: this.parent_id
							}
							let res = await this.$axios.post('user/register', params);
							if (res.code == 1) {
								this.$message({ offset:100,
									message: this.$t('newText.msg93'),
									type: 'success'
								});
								localStorage.removeItem('regFrom');
								this.$router.push("/login")
							}
						} catch (error) {
							console.log(error);
						}
					} else {
						console.log('error submit!!')
						return false
					}
				})
			},
			// 倒计时
			countDown() {
				this.intervalBtn = setInterval(() => {
					if (this.countNum <= 0) {
						this.codeShow = false;
						this.verShow=false;
						clearInterval(this.intervalBtn)
						this.countNum = 60;
					};
					this.countNum--;
				}, 1000);
			},
			async verification(value) {
				let _this = this;
				if(this.verShow)return;
				try {
					let params = {
						email: _this.ruleForm.mailbox,
						event: 'register'
					}
					let res = await this.$axios.post('ems/send', params);
					this.verShow=true;
					if (res.code == 1) {
						this.$message({ offset:100,
							message: this.$t('newText.msg85'),
							type: 'success'
						});
						this.codeShow = true
						this.countDown()
					}
				} catch (error) {
					console.log(error);
				}
			},
			goOther(url) {
				console.log(url);
				//卡布达写的
				localStorage.setItem('num',this.categoryIndex)
				//
				this.$router.push('/login');
			}
		}
	}
</script>
