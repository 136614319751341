<template>
	<div>
		<header style="z-index: 99999;" class="flex_just_disp"
			:class="[navShow?'header_show':'header_Hide animation_outLeft']">
			<div class="head_box">
				<div class="header_left flex_disp">
					<div class="logIn_icon icon_image">
						<router-link to="/">
							<img src="@/assets/logo.png" @click="goHome">
						</router-link>
					</div>
					<div class="header_list flex_just_disp header_text" v-if="show"  @click="accountInformationObj">
						<router-link :to="i.to" v-for="(i,ix) in $t('language.list')"
							:class="[name==i.name?'header_color':'']">
							<span>{{i.title}}</span>
						</router-link>
					</div>
				</div>
				<DropDown style="position: absolute;right: 0;"></DropDown>
			</div>

			<!-- <div class=""></div> -->
		</header>
	</div>
</template>

<script>
	import DropDown from './DropDown.vue';
	export default {
		props: {
			show: {
				type: [Boolean],
				default: true
			},
			navShow: {
				type: [Boolean],
				default: true
			}
		},
		data() {
			return {
				name: '',
				
			}
		},
		components: {
			DropDown
		},
		watch: {
			$route(value) {
				this.name = value.name;
				console.log(this.name)
			}
		},
		mounted() {
			this.name = this.$route.name;
			this.accountInformationObj();
		},
		methods: {
			// accountInformationObj(){
			// console.log(111)	
			// },
			// fnfn(){
			// console.log(111)	
			// },
			goHome() {
				this.$route.push('/')
				// console.log(111)
			},
			async accountInformationObj() {
				let _this = this
				try {
					let res = await this.$axios.post('match/upLeftDays');
					if (res.code == 1) {
							// this.$t('language.list').splice(5,1);
							localStorage.setItem('dayNum',res.data.daynum);
					}
				} catch (error) {
					console.log(error);
				}
			},
		}
	}
</script>

<style lang="less">
	.head_box {
		width: 1360px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		position: relative;
	}

	.header_color {
		color: #932224 !important;
		font-weight: bold;
		animation-name: rubberband-vertical;
		animation-duration: 1s !important;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;
	}

	@keyframes rubberband-vertical {
		0% {
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
		}

		30% {
			-webkit-transform: scale3d(.75, 1.25, 1);
			transform: scale3d(0.9, 1, 1);
		}

		40% {
			-webkit-transform: scale3d(1.25, .75, 1);
			transform: scale3d(1.25, .75, 1);
		}

		50% {
			-webkit-transform: scale3d(.85, 1.15, 1);
			transform: scale3d(.85, 1.15, 1);
		}

		65% {
			-webkit-transform: scale3d(1.05, .95, 1);
			transform: scale3d(1.05, .95, 1);
		}

		75% {
			-webkit-transform: scale3d(.95, 1.05, 1);
			transform: scale3d(.95, 1.05, 1);
		}

		100% {
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
		}
	}

	header {
		background-color: #fff;
		height: 120px;
		// padding: 5px 278px;
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		border-bottom: 1px solid #f2f2f2;;
		a {
			text-decoration: none;
			color: #000;
		}

		.header_left {
			font-weight: bold;
			>.logIn_icon {
				width: 119px;
				height: 0;
				margin-right: 40px;

				img {
					width: 119px;
					height: 108px;
					// margin-right: 72px;
				}
			}

			>.header_list {
				font-size: 20px;
				width: 900px;
				text-align: center;
				opacity: 0;

				>a:hover {
					color: #932224;
					transition: all 0.3s linear 0s;
					// font-weight: bold;
					display: inline-block !important;
				}

				>a {
					width: 16.6%;
					min-width: 16.6%;
					max-width: 16.6%;
					display: inline-block !important;
				}
			}

			>.header_text {
				transition: all 1s linear 0s;
				opacity: 1;
			}
		}

	}

	.header_show {
		visibility: visible;
		transition: all 1s linear 0s;
		height: 108px !important;

		.logIn_icon {
			height: 108px !important;

			img {
				height: 108px !important;
			}
		}
	}

	.header_Hide {
		visibility: hidden;
		padding: 0;
		height: 0 !important;
		transition: all 1s linear 0s;
		color: #fff;

		.logIn_icon {
			height: 0px !important;
		}
	}
</style>
