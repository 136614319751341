<template>
	<div class="foruploading">
		<h2>{{$t('newText.msg78')}}</h2>
		<div class="foruploading_conten">
			<router-view></router-view>
		</div>
	</div>
</template>
<script>
	export default{
		data(){
			return{
				stepIndex:1
			}
		},
		watch:{
			'$store.state.schedule':(value)=>{
				// this.stepIndex=value+1;
				console.log(value)
			}
		},
	}
</script>

<style lang="less">
	body{
		background-color: #F5F5F5;
	}
	.foruploading{
		text-align: center;
		>h2{
			font-size: 44px;
			padding: 44px 0;
		}
		>.foruploading_conten{
			width: 1301px;
			margin: 0 auto;
			padding-bottom: 141px;
		}
	}
</style>
