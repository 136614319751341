<style lang="less" scoped>
	body {
		background: #f5f5f5;
	}
	.logIn_box {
		font-size: 22px;
		.el-input__inner:focus {
			transition: all 1.4s linear 0s;
			border-color: #932224 !important;
		}
		>.logIn_title{
			color: #000;
			font-size: 32px;
			font-weight: 400;
		}
		.el-input__inner{
			font-size: 20px;
			height: 58px !important;
			border-radius: 0;
		}
		.el-button{
			width: 100%;
			height: 58px;
			background-color: #932224!important;
			font-size: 22px;
			border-color: #932224 !important;
		}
		.el-button--primary:hover{
			background-color:#932224;
		}
		width: 728px;
		margin: 60px auto;
		background-color: #fff;
		padding: 40px 0;
		>.logIn_conten{
			width: 494px;
			margin: 0 auto;
			padding-top: 55px;
			>.login_infuseText{
				color: #932224;
				text-align: left;
				padding-top: 49px;
			}
			.login_btn{
				padding-top: 40px;
			}
			>.login_rest{
				padding-top: 34px;
				>.login_rest_box{
					font-size: 22px;
					justify-content: center;
					color: #666666;
					width: 237px;
					height: 58px;
					border: 1px solid #979797;
					>i{
						padding-left: 10px;
					}
				}
			}
		}
		>.logIn_icon {
			width: 167px;
			height: 150px;
			margin: 0 auto;
		}
	}
	.code_box{
		position: relative;
		>span{
			position: absolute;
			font-size: 18px;
			text-align: center;
			border-left: 1px solid #979797;
			height: 21px;
			line-height: 21px;
			right: 0;
			width: 154px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
</style>
<template>
	<div class="logIn">
		<div class="logIn_box">
			<div class="logIn_title"><span>{{$t('logIn.setpassword.title')}}</span></div>
			<div class="logIn_conten">
				<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"
					class="demo-ruleForm">
					<el-form-item prop="password">
						<FormInput @inputChange="inputChange" :matter="new $Custom ($t('logIn.setpassword.importPassword'))"
							:fatherValue.sync="ruleForm.password" :iValue="ruleForm.password"></FormInput>
						<p>{{$t('logIn.setpassword.infuse')}}</p>
					</el-form-item>
					<el-form-item prop="ispassword">
							<FormInput @inputChange="inputChange" :matter="new $Custom ($t('logIn.twoPassword'))"
								:fatherValue.sync="ruleForm.ispassword" :iValue="ruleForm.ispassword"></FormInput>
					</el-form-item>
					<el-form-item>
						<div class="login_btn">
							<el-button type="primary" @click="submitForm('ruleForm')">{{$t('logIn.setpassword.abbr')}}</el-button>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import FormInput from '@/components/ele/formInput.vue';
	import {
		isPasswrd
	} from '@/components/ele/formRules.js'
	export default {
		components:{
			FormInput
		},
		data() {
			let validatePassWord = (rule, value, callback) => {
				if (!isPasswrd(value)) {
					callback(new Error(this.$t('newText.msg33')))
				} else {
					callback()
				}
			}
			var validatePass = (rule, value, callback) => {
				console.log(value,this.ruleForm.password)
				if (value === '') {
					callback(new Error(this.$t('newText.msg34')));
				} else if (value !== this.ruleForm.password) {
					callback(new Error(this.$t('newText.msg35')));
				} else {
					callback();
				}
			};
			return {
				ruleForm:{
					password:"",
					ispassword:""
				},
				rules: {
					password: [{
						required: true,
						message: this.$t('newText.msg37'),
						trigger: 'blur'
					}, {
						validator: validatePassWord,
						trigger: 'blur'
					}, {
						min: 8,
						message: this.$t('newText.msg38'),
						trigger: 'blur'
					}],
					ispassword: [{
						validator: validatePass,
						trigger: 'blur'
					}]
				},
			}
		},
		mounted() {
			if(localStorage.getItem('newPassFrom')){
				let newPassFrom=JSON.parse(localStorage.getItem('newPassFrom'));
				this.ruleForm=newPassFrom.ruleForm;
			}
		},
		methods:{
			inputChange(){
				JSON.stringify(localStorage.setItem('newPassFrom',this.$data));
			},
			async submitForm(value) {
				this.$refs.ruleForm.validate(async valid => {
					// if(this.ruleForm.password!=this.ruleForm.ispassword)
					if (valid) {
						try {
							let _this = this
							let params = {
								type:1,
								email:this.$route.query.mailbos,
								code:this.$route.query.fillcode,
								newpassword:this.ruleForm.password
							}
							let res = await this.$axios.post('user/resetpwd', params);
							if (res.code == 1) {
								this.$message({ offset:100,
									message: this.$t('newText.msg39'),
									type: 'success'
								});
								this.$router.push("/login")
								localStorage.removeItem('newPassFrom');
							}
						} catch (error) {
							console.log(error);
						}
					} else {
						console.log('error submit!!')
						return false
					}
				})
			},
			goOther(url){
				console.log(url);
				this.$router.push('/forget');
			}
		}
	}
</script>
