<style lang="less" scoped>
.content{
	background: url(../assets/countdown.png) no-repeat;
	background-size: cover;
	width: 193px;
	height: 379px;
	color: #FFFFFF;
	position: fixed;
	right: 38px;
	top: 30%;
	z-index: 12;
	.content_box{
		width: 193px;
		height: 379px;
		position: relative;
		>div{
			position: absolute;
			width: 100%;
			text-align: center;
		}
		.title{
			font-size: 18px;
			top: 40px;
		}
		.text{
			font-size: 38px;
			top: 78px;
		}
		.number{
			font-size: 78px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}
		.numberText{
			font-size: 40px;
			position: absolute;
			left: 50%;
			top: 50%;
			font-weight: bold;
			transform: translate(-50%,-50%);
			
		}
		.days{
			font-size: 38px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 40px;
		}
	}
	
	
}
.content {
  animation-name: slide-in-fwd-right;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}
@keyframes slide-in-fwd-right {
  0%{
    -webkit-transform: translateZ(-1400px) translateX(1000px);
    transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100%{
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
</style>
<template>
  <div class="content">
	  <div class="content_box">
		  <div class="title">
		  		  {{$t("newText.msg43")}}
		  </div>
		  <div class="text" v-if="conten.daynum!=0">
		  		   {{$t("newText.msg44")}}
		  </div>
		  <div class="number" v-if="conten.daynum!=0">
		  		  {{conten.daynum}}
		  </div>
		  <div class="numberText" v-if="conten.daynum==0">
		  		 <span> {{$t("newText.msg45")}}</span>
		  </div>
		  <div class="days" v-if="conten.daynum!=0">
		  		  DAYS
		  </div>
	  </div>
  </div>
</template>

<script>
export default {
  components: {
    
  },
  data(){
	  return{
		  conten:0
	  }
  },
  mounted() {
  	this.accountInformationObj();
  },
  methods:{
	  async accountInformationObj() {
	  	let _this = this
	  	try {
	  		let res = await this.$axios.post('match/upLeftDays');
	  		if (res.code == 1) {
	  			this.conten = res.data;
				console.log(data);
	  		}
	  	} catch (error) {
	  		console.log(error);
	  	}
	  
	  },
  }
}
</script>

