<style lang="less" scoped>
	.home {
		overflow: hidden;
	}

	body {
		background-color: #fff;
	}

	.competition {
		width: 100%;
		height: 100%;
		background: url("http://39.103.217.231/uploads/20211208/c2171d811c3920d6d9ef5dca7c1ca61a.jpeg");
		background-repeat: no-repeat;
		background-size: cover;
	}

	.megagame {
		width: 100%;
		height: 100%;
		position: relative;
		color: #fff;
		font-size: 38px;
		width: 1360px;
		margin: 0 auto;
		justify-content: flex-start;

		>.carouIndex_box {
			position: absolute;
			bottom: 32px;
			z-index: 9;
			right: 0;
			text-align: left;

			>p {
				font-size: 12px;
				color: #fff;
				padding-bottom: 6px;
			}

			>.carouIndex_conten {
				>p {
					width: 72px;
					height: 3px;
					background-color: #fff;
					margin-right: 4px;
				}

				>.carouIndex_color {
					width: 72px;
					background-color: #C20D0A;
					transition: all .8s linear 0s;
				}
			}
		}

		>.below_box {
			position: absolute;
			bottom: 21px;
			left: 50%;
			font-size: 22px;
			color: #fff;
			z-index: 2;

			>.below_icon {
				width: 37px;
				height: 21px;
				margin: 0 auto 13px auto;
			}
		}

		>.megagame_box {
			position: absolute;
			bottom: 167px;
			z-index: 999;

			>.megagame_conten {
				padding-top: 37px;
				font-size: 38px;
				font-weight: bold;
				justify-content: space-between;

				>span {
					padding-right: 71px;
				}

				>.megagame_icon_box {
					box-shadow: 4px 2px 8px 0px rgba(63, 63, 63, 0.5);
					border-radius: 3px;
					border: 2px solid #FFFFFF;
					padding: 9px 19px;
					font-size: 22px;

					>.icon_image {
						width: 51px;
						height: 51px;
					}

					>span {
						padding-left: 7px;
					}
				}
			}
		}
	}

	.track_all {
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 20px;
		padding: 80px 0 59px 0;

		>.track {
			>h3 {
				font-size: 44px;
				color: #000;
			}

			// overflow: hidden;

			>.track_flex {
				margin-top: 71px;
				align-items: flex-start;

				>.track_box:hover {
					>.track_conten {
						position: absolute;
						height: 55%;
						width: 100%;
						bottom: 0;
						transition: all 0.3s linear 0s;

						.track_ck {
							opacity: 1;
							transition: all 0.3s linear 0s;
						}
					}
				}

				>.track_box {
					.animation_icon_0 {
						animation-name: rotate-center;
						animation-duration: 4s;
						animation-timing-function: linear;
						animation-delay: 0s;
						animation-iteration-count: infinite;
						animation-direction: normal;
						animation-fill-mode: none;
					}

					@keyframes rotate-center {
						0% {
							-webkit-transform: rotate(0);
							transform: rotate(0);
						}

						100% {
							-webkit-transform: rotate(360deg);
							transform: rotate(360deg);
						}
					}

					.animation_icon_1 {
						animation-name: rotate-center2;
						animation-duration: 4s;
						animation-timing-function: linear;
						animation-delay: 0s;
						animation-iteration-count: infinite;
						animation-direction: normal;
						animation-fill-mode: none;
					}

					@keyframes rotate-center2 {
						0% {
							-webkit-transform: rotate(0);
							transform: rotate(0);
						}

						100% {
							-webkit-transform: rotate(-360deg);
							transform: rotate(-360deg);
						}
					}

					.animation_icon_2 {
						animation-name: rotate-center;
						animation-duration: 4s;
						animation-timing-function: linear;
						animation-delay: 0s;
						animation-iteration-count: infinite;
						animation-direction: normal;
						animation-fill-mode: none;
					}
				}

				>.track_box {
					width: 431.78px;
					min-height: 549px;
					position: relative;

					.track_ck {
						// width: 240px;
						color: #FFFFFF;
					}

					>.track_icon_box {
						width: 100%;
						height: 295px;
						justify-content: center;
						align-items: center;
						box-shadow: 0px 5px 6px 4px rgba(228, 228, 228, 0.5);

						>.track_icon {
							width: 206px;
							height: 206px;
						}
					}

					>.track_conten {
						background-color: #932224;
						height: 254px;
						text-align: center;

						>p:nth-child(1) {
							padding: 60px 0 54px 0;
							font-size: 28px;
						}

						.track_ck {
							// width: 124px;
							padding: 10px 20px;
							border-radius: 8px;
							background: #FFFFFF;
							border-radius: 1px;
							color: #932224;
							margin: 30px auto;
							font-size: 18px;
							padding: 10px 12px;
							border-radius: 2px;
							text-align: center;
							opacity: 0;
							display: inline-block !important;
						}
					}
				}

				>.track_box:nth-child(2) {
					margin: 0 32px;
				}
			}
		}
	}

	.judge {
		background: url("../../assets/judges_back.png");
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 923px !important;
		position: relative;

		>.judge_box {
			/deep/.el-carousel__arrow--left {
				color: #FFFFFF;
				font-size: 42px;
				font-weight: bold;
				background: rgba(0, 0, 0, 0.4) !important;
				width: 52px;
				height: 52px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			/deep/.el-carousel__arrow--right {
				color: #FFFFFF;
				font-size: 42px;
				font-weight: bold;
				background: rgba(0, 0, 0, 0.4) !important;
				width: 52px;
				height: 52px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			align-items: center;
			justify-content: center;
			width: 1350px;
			// height: 923px;

			.judge_isbox {
				height: 100%;
				width: 100%;

				>div:first-child {
					margin: 0 115px;
					width: 370px;

					>.judge_icon {
						width: 367px;
						height: 367px;
						border-radius: 50%;

						img {
							object-fit: cover;
						}
					}

					>h3 {
						font-size: 38px;
						padding: 17px 0 15px 0;
					}

					>p {
						font-size: 18px;
					}
				}

				>div:last-child {
					text-align: left;

					>.judge_title {
						font-size: 44px;
						width: 600px;
					}

					>.judge_motto {
						font-size: 58px;
						padding: 56px 0;
					}

					>.judge_conten {
						padding-top: 26px;
						border-top: 1px solid #979797;
						width: 600px;

						>li {
							color: #666666;
							padding-bottom: 32px;

							p {
								font-size: 23px;
							}
						}
					}

					>.judge_all:hover {
						// border: ;
						color: #fff !important;
						border-color: #A11820;

						>p {
							background-color: #A11820;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							bottom: 0;
							transition: all .3s linear 0s;
						}
					}

					>.judge_all {
						padding: 9px 10px;
						font-size: 16px;
						justify-content: center;
						color: #666666;
						border: 1px solid #666666;
						// width: 125px;
						position: relative;
						display: inline-block;

						>p {
							position: absolute;
							height: 0;
							width: 0;
							transition: all 0.50s ease-in-out 0s;
								
						}

						>span {
							padding-right: 15px;
							position: relative;
							z-index: 8;
						}

						>i {
							position: relative;
							z-index: 8;
						}
					}
				}
			}
		}
	}

	.information {
		height: 495px;
		margin: 0 auto;
		background-color: #ccc;
		color: #fff;
		text-align: left;
		background: url(../../assets/messageback.png);
		background-repeat: no-repeat;
		background-size: cover;
		margin-top: 56px;

		>.information_box {
			padding: 50px 0;
			margin: 0 auto;
			width: 1360px;

			>h3 {
				font-size: 44px !important;
				display: inline-block;
				padding-bottom: 26px;
				border-bottom: 1px solid #FFFFFF;
			}

			>.information_conten {
				margin-top: 51px;

				>div {
					.information_all:hover {
						color: #fff !important;
						border-color: #A11820;

						>p {
							background-color: #A11820;
							width: 100%;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							height: 100%;
							transition: all .3s linear 0s;
						}
					}

					.information_all {
						// width: 124px;
						height: 34px;
						display: inline-block;
						line-height: 34px;
						background: #932224;
						border-radius: 1px;
						font-size: 16px;
						text-align: center;
						position: relative;
						padding: 0 5px;

						>p {
							position: absolute;
							width: 0;
							height: 100%;
							left: 0;
							transition: all 0.50s ease-in-out 0s;
						}
					}

					.information_cmbox {
						align-items: flex-start;

						>.information_leText {
							padding-right: 29px;
							max-width: 232px;
							min-width: 190px;
							text-align: left;

							h3 {
								font-size: 20px;
							}

							p {
								padding-top: 20px;
								font-size: 16px;
							}
						}

						.advertDh {
							animation-duration: 1s;
							animation-timing-function: ease;
							animation-delay: 0s;
							animation-iteration-count: 1;
							animation-direction: normal;
							animation-fill-mode: none;

							@keyframes flip-in-diagonal-bl {
								0% {
									-webkit-transform: rotate3d(1, 1, 0, 80deg);
									transform: rotate3d(1, 1, 0, 80deg);
									opacity: 0;
								}

								100% {
									-webkit-transform: rotate3d(1, 1, 0, 0deg);
									transform: rotate3d(1, 1, 0, 0deg);
									opacity: 1;
								}
							}
						}

						.advertising_icon {
							width: 218px;
							height: 193px;
							position: relative;
							animation-duration: 1s;
							animation-timing-function: ease;
							animation-delay: 0s;
							animation-iteration-count: 1;
							animation-direction: normal;
							animation-fill-mode: none;
							cursor: pointer;
							@keyframes flip-in-diagonal-bl {
								0% {
									-webkit-transform: rotate3d(1, 1, 0, 80deg);
									transform: rotate3d(1, 1, 0, 80deg);
									opacity: 0;
								}

								100% {
									-webkit-transform: rotate3d(1, 1, 0, 0deg);
									transform: rotate3d(1, 1, 0, 0deg);
									opacity: 1;
								}
							}

							>.advertising_box {
								padding: 6px 16px;
								font-size: 18px;
								background: rgba(250, 250, 250, 0.77);
								position: absolute;
								top: 0;
								left: 0;
								z-index: 9;
							}

							>.advertising_text {
								font-size: 16px;
								position: absolute;
								left: 50%;
								bottom: 24px;
								transform: translateX(-50%);
							}
						}
					}
				}

				>div:first-child {
					margin-right: 140px;
				}
			}
		}
	}

	.partner {
		text-align: center;
		font-size: 38px;
		width: 1360px;
		margin: 0 auto;
		padding-bottom: 116px;
		overflow: hidden;

		>.partner_title {
			padding: 70px 0 50px 0;

		}

		.partner_isConten {
			overflow: hidden;
		}

		.partner_box {
			padding: 20px 0;
			justify-items: flex-start;
			width: 100%;
			flex-wrap: wrap;
			border-bottom: 1px solid #DEDEDE;
			overflow: hidden;

			// transform: translateX(-59px);
			.partner_box_conten {
				width: 100%;
				transition: all 3s linear 0s;
				flex-wrap: nowrap;
				transform: translateX(-500px);
			}

			.partner_icon_box {
				width: 14.28%;
				max-width: 14.28%;
				min-width: 14.28%;
				height: 73px;

				>.partner_icon {
					width: 125px;
					margin: 0 auto;
				}
			}
		}
	}

	.carousel_box {
		height: 100%;
		overflow-y: scroll;
	}

	.synopsis_box {
		// background: url("http://39.103.217.231/uploads/20211208/c2171d811c3920d6d9ef5dca7c1ca61a.jpeg");
		// background-repeat: no-repeat;
		// background-size: cover;
		position: relative;
		overflow: hidden;

		.synopsis_conten_box {
			width: 1360px;
			margin: 0 auto;
			position: relative;
			height: 100%;
		}

		.synopsis_bk {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			>.synopsis_bk_icon {
				width: 100%;
				height: 100%;
			}
		}

		.synopsis {
			width: 657px;
			padding: 33px 39px;
			color: #fff;
			opacity: 0;
			background: rgba(161, 24, 32, 0.83);
			position: absolute;
			bottom: 180px;
			font-size: 44px;
			text-align: left;

			>h3 {
				font-size: 44px;
			}

			>p {
				font-size: 28px;
				padding: 28px 0 35px 0;
			}

			>.synopsis_conten:hover {
				color: #932224 !important;
				border-color: #932224;

				>p {
					background-color: #FFFFFF !important;
					left: 0;
					top: 0;
					bottom: 0;
					right: 0;
					width: 100%;
					transition: all .3s linear 0s;
				}

				>i {
					left: 20px;
				}
			}

			>.synopsis_conten {
				// width: 222px;
				display: inline-block !important;
				padding: 17px 45px;
				font-size: 22px;
				padding: 17px 46px;
				justify-content: center;
				align-items: center;
				border: 1px solid #FFFFFF;
				position: relative;
				animation-name: slide-in-bottom;
				animation-duration: 1s;
				animation-timing-function: ease;
				animation-delay: 0s;
				animation-iteration-count: 1;
				animation-direction: normal;
				animation-fill-mode: none;

				@keyframes slide-in-bottom {
					0% {
						-webkit-transform: translateY(1000px);
						transform: translateY(1000px);
						opacity: 0;
					}

					100% {
						-webkit-transform: translateY(0);
						transform: translateY(0);
						opacity: 1;
					}
				}

				>p {
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					width: 0;
					transition: all 0.50s ease-in-out 0s;
				}

				>span {
					position: relative;
					z-index: 9;
					padding-right: 15px;
				}

				>i {
					position: relative;
					top: 2px;
					z-index: 9;
				}
			}
		}
	}

	.synopsisamin {
		opacity: 1 !important;
		animation-name: slide-in-fwd-left;
		animation-duration: 1s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;

		@keyframes slide-in-fwd-left {
			0% {
				-webkit-transform: translateZ(-1400px) translateX(-1000px);
				transform: translateZ(-1400px) translateX(-1000px);
				opacity: 0;
			}

			100% {
				-webkit-transform: translateZ(0) translateX(0);
				transform: translateZ(0) translateX(0);
				opacity: 1;
			}
		}

		>h3 {
			-webkit-animation: bounce-in-top 2.5s both;
			animation: bounce-in-top 2.5s both;

			@-webkit-keyframes bounce-in-top {
				0% {
					-webkit-transform: translateY(-500px);
					transform: translateY(-500px);
					-webkit-animation-timing-function: ease-in;
					animation-timing-function: ease-in;
					opacity: 0;
				}

				38% {
					-webkit-transform: translateY(0);
					transform: translateY(0);
					-webkit-animation-timing-function: ease-out;
					animation-timing-function: ease-out;
					opacity: 1;
				}

				55% {
					-webkit-transform: translateY(-65px);
					transform: translateY(-65px);
					-webkit-animation-timing-function: ease-in;
					animation-timing-function: ease-in;
				}

				72% {
					-webkit-transform: translateY(0);
					transform: translateY(0);
					-webkit-animation-timing-function: ease-out;
					animation-timing-function: ease-out;
				}

				81% {
					-webkit-transform: translateY(-28px);
					transform: translateY(-28px);
					-webkit-animation-timing-function: ease-in;
					animation-timing-function: ease-in;
				}

				90% {
					-webkit-transform: translateY(0);
					transform: translateY(0);
					-webkit-animation-timing-function: ease-out;
					animation-timing-function: ease-out;
				}

				95% {
					-webkit-transform: translateY(-8px);
					transform: translateY(-8px);
					-webkit-animation-timing-function: ease-in;
					animation-timing-function: ease-in;
				}

				100% {
					-webkit-transform: translateY(0);
					transform: translateY(0);
					-webkit-animation-timing-function: ease-out;
					animation-timing-function: ease-out;
				}
			}

			@keyframes bounce-in-top {
				0% {
					-webkit-transform: translateY(-500px);
					transform: translateY(-500px);
					-webkit-animation-timing-function: ease-in;
					animation-timing-function: ease-in;
					opacity: 0;
				}

				38% {
					-webkit-transform: translateY(0);
					transform: translateY(0);
					-webkit-animation-timing-function: ease-out;
					animation-timing-function: ease-out;
					opacity: 1;
				}

				55% {
					-webkit-transform: translateY(-65px);
					transform: translateY(-65px);
					-webkit-animation-timing-function: ease-in;
					animation-timing-function: ease-in;
				}

				72% {
					-webkit-transform: translateY(0);
					transform: translateY(0);
					-webkit-animation-timing-function: ease-out;
					animation-timing-function: ease-out;
				}

				81% {
					-webkit-transform: translateY(-28px);
					transform: translateY(-28px);
					-webkit-animation-timing-function: ease-in;
					animation-timing-function: ease-in;
				}

				90% {
					-webkit-transform: translateY(0);
					transform: translateY(0);
					-webkit-animation-timing-function: ease-out;
					animation-timing-function: ease-out;
				}

				95% {
					-webkit-transform: translateY(-8px);
					transform: translateY(-8px);
					-webkit-animation-timing-function: ease-in;
					animation-timing-function: ease-in;
				}

				100% {
					-webkit-transform: translateY(0);
					transform: translateY(0);
					-webkit-animation-timing-function: ease-out;
					animation-timing-function: ease-out;
				}
			}
		}

		>p {
			animation-name: slide-in-right;
			animation-duration: 1s;
			animation-timing-function: ease;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: none;

			@keyframes slide-in-right {
				0% {
					-webkit-transform: translateX(1000px);
					transform: translateX(1000px);
					opacity: 0;
				}

				100% {
					-webkit-transform: translateX(0);
					transform: translateX(0);
					opacity: 1;
				}
			}
		}
	}

	.car_contenAmin {
		opacity: 1;

		.track_box0 {
			animation-name: slide-in-fwd-left;
			animation-duration: 1s;
			animation-timing-function: ease;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: none;
		}

		@keyframes slide-in-fwd-left {
			0% {
				-webkit-transform: translateZ(-1400px) translateX(-1000px);
				transform: translateZ(-1400px) translateX(-1000px);
				opacity: 0;
			}

			100% {
				-webkit-transform: translateZ(0) translateX(0);
				transform: translateZ(0) translateX(0);
				opacity: 1;
			}
		}

		.track_box2 {
			animation-name: slide-in-fwd-right;
			animation-duration: 1s;
			animation-timing-function: ease;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: none;
		}

		@keyframes slide-in-fwd-right {
			0% {
				-webkit-transform: translateZ(-1400px) translateX(1000px);
				transform: translateZ(-1400px) translateX(1000px);
				opacity: 0;
			}

			100% {
				-webkit-transform: translateZ(0) translateX(0);
				transform: translateZ(0) translateX(0);
				opacity: 1;
			}
		}

		.track_box1 {
			animation-name: slide-in-elliptic-bottom-fwd;
			animation-duration: 1s;
			animation-timing-function: ease;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: none;
		}

		@keyframes slide-in-elliptic-bottom-fwd {
			0% {
				-webkit-transform: translateY(600px) rotateX(30deg) scale(0);
				transform: translateY(600px) rotateX(30deg) scale(0);
				-webkit-transform-origin: 50% 100%;
				transform-origin: 50% 100%;
				opacity: 0;
			}

			100% {
				-webkit-transform: translateY(0) rotateX(0) scale(1);
				transform: translateY(0) rotateX(0) scale(1);
				-webkit-transform-origin: 50% -1400px;
				transform-origin: 50% -1400px;
				opacity: 1;
			}
		}

		.track_title {
			animation-name: focus-in-contract;
			animation-duration: 1s;
			animation-timing-function: ease;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: none;
		}

		@keyframes focus-in-contract {
			0% {
				letter-spacing: 1em;
				filter: blur(12px);
				opacity: 0;
			}

			100% {
				filter: blur(0);
				opacity: 1;
			}
		}
	}

	.juage_title_Amin {
		.judge_icon {
			animation-name: rotate-in-vertical;
			animation-duration: 2s;
			animation-timing-function: ease;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: none;

			/* shorthand 
			  animation: rotate-in-vertical 1s ease 0s 1 normal none;
			  */
			@keyframes rotate-in-vertical {
				0% {
					transform: rotateY(-120deg);
					opacity: 0;
				}

				100% {
					transform: rotateY(0deg);
					opacity: 1;
				}
			}
		}

		h3,
		p,
		.judge_all {
			animation-name: focus-in-zoomout;
			animation-duration: 2s;
			animation-timing-function: ease;
			animation-delay: 0s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: none;

			/* shorthand 
			  animation: focus-in-zoomout 1s ease 0s 1 normal none;
			  */
			@keyframes focus-in-zoomout {
				0% {
					transform: translateY(300px);
					opacity: 0;
				}

				100% {
					transform: translateY(0px);
					opacity: 1;
				}
			}
		}
	}
</style>
<style>
	.MsoNormal span {
		font-size: 15px; 
	}
</style>
<template>
	<div class="home" ref="scroll">
		<!-- 1 -->
		<div>
			<CarouSel :postericon="backIcon" :height="(windowHeight-65) + 'px'" :indicator-position="'none'"
				:carouselItem="positionList" @elChange="elChange" :initial_index="homeCurrent">
				<template v-slot:conten>
					<div class="megagame">
						<div class="megagame_box focus_contract">
							<h3>{{$t('home.contestTitle')}}</h3>
							<div class="megagame_conten flex_disp">
								<span>{{$t('home.contestName')}}</span>
								<div class="megagame_icon_box cursorS flex_disp animation_breathe">
									<div class="icon_image megagame_icon">
										<img src="@/assets/homeLogo.png" alt="">
									</div>
									<span class="flex_disp"
										@click="$router.push({path:'ForUploading'})">{{$t('home.ImmediateName')}} <i
											class="el-icon-arrow-right"></i></span>
								</div>
							</div>
						</div>
						<div class="below_box cursorS" @click="toPinglun()">
							<div class="below_icon icon_image  animation_below_text">
								<img src="../../assets/bom_icon.png">
							</div>
							<span>{{$t('home.more')}}</span>
						</div>
						<div class="carouIndex_box">
							<p>0{{homeCurrent+1}}/0{{positionList.length}}</p>
							<div class="carouIndex_conten flex_disp">
								<p v-for="(item,index) in positionList" :key="index" @click.stop="homeCurrent=index"
									:class="[index===homeCurrent?'carouIndex_color':'']" class="cursorS"></p>
							</div>
						</div>
					</div>
				</template>
			</CarouSel>
		</div>
		<div :style="'height:'+windowHeight+'px'" id="synopsis_box" :class="[cartoon[0]?'synopsis_boxZh':'']"
			class="synopsis_box">
			<div class="synopsis_bk">
				<div class="synopsis_bk_icon icon_image">
					<img src="@/assets/competitionLogo.png">
				</div>
			</div>
			<div class="synopsis_conten_box">
				<div class="synopsis" id="synopsis" :class="[cartoon[0]?'synopsisamin':'']">
					<h3>{{$t('home.contestBrief')}}</h3>
					<p>{{$t('home.brief')}}</p>
					<div class="synopsis_conten cursorS flex_disp" @click="$router.push({path:'/aboutContest'})">
						<p></p>
						<span>{{$t('home.knowaBout')}}</span>
						<i class="el-icon-right"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="carousel_box">
			<div class="track_all flex_disp">
				<div class="track">
					<h3 class="track_title">{{$t('home.trackName')}}</h3>
					<div class="flex_just_disp track_flex" id="car_conten">
						<div class="track_box" :class="'track_box'+index" v-for="(item,index) in trackData"
							:key="index">
							<div class="track_icon_box flex_disp">
								<div class="track_icon icon_image" :class="'animation_icon_'+index">
									<img :src="item.icon">
								</div>
							</div>
							<div class="track_conten">
								<p>{{item.title}}</p>
								<p>{{item.generalize}}</p>
								<div class="track_ck cursorS"
									@click="$router.push({path:'/aboutContest',query:{ef:'gome',initIndex:index,index:1}})">
									{{$t('home.wmore')}}
									<i class="el-icon-right"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="judge" id="judge_box">
				<div class="judge_box">
					<CarouSel height="40.29948vw" :initial_index="0" :indicator-position="'none'">
						<template v-slot:conten>
							<el-carousel-item v-for="(item,index) in expertSthink" :key="index">
								<div class="judge_isbox flex_disp" :class="[cartoonShow?'juage_title_Amin':'']">
									<div>
										<div class="judge_icon icon_image">
											<img :src="item.avatar">
										</div>
										<h3>{{item.en_name}}</h3>
										<p>{{item.en_position}}</p>
									</div>
									<div>
										<div class="judge_title">
											<p> {{$t('home.IntroductionBrief')}}</p>
										</div>
										<!-- <div class="judge_motto">“设计来源于生活”</div> -->
										<ul class="judge_conten">
											<li>
												<p v-html="item.en_text"></p>
											</li>
										</ul>
										<div class="judge_all cursorS"
											@click="$router.push({path:'expertSthink'})">
											<span>{{$t('newWord.judgeTitle')}}</span>
											<i class="el-icon-right"></i>
											<p></p>
										</div>
									</div>
								</div>
							</el-carousel-item>
						</template>
					</CarouSel>
				</div>
			</div>
			<div class="information">
				<div class="information_box">
					<h3>{{$t('home.information')}}</h3>
					<div class="information_conten flex_just_disp">
						<div>
							<div class="information_cmbox flex_disp" v-if="contentList.length>0">
								<div class="information_leText"
									@click="$router.push({path:'InFormationDetail',query:{id:contentList[0].id }})">
									<h3 class="text_2_size floating_hovr ">{{contentList[0].ne_title||''}}</h3>
									<p class="text_2_size floating_hovr ">{{contentList[0].ne_vice_title||''}}</p>
								</div>
								<div class="advertising_icon icon_image"
									@click="$router.push({path:'InFormationDetail',query:{id:contentList[0].id }})">
									<img :src="contentList[0].ne_img" />
									<div class="advertising_box" v-if="contentList[0].ne_is_guanggao==1">
										<span>{{$t('newText.msg87')}}</span>
									</div>
								</div>
							</div>
							<div class="information_all cursorS"
								@click="$router.push({path:'InFormationDetail',query:{id:contentList[0].id }})">
								<span>{{$t('home.knowaBout')}}</span>
								<i class="el-icon-right"></i>
							</div>
						</div>
						<div>
							<div class="information_cmbox flex_disp" v-if="contentList.length>0">
								<div class="information_leText"
									@click="$router.push({path:'InFormationDetail',query:{id:contentList[1].id }})">
									<h3 class="text_2_size floating_hovr">{{contentList[1].ne_title}}</h3>
									<p class="text_2_size floating_hovr">{{contentList[1].ne_vice_title}}</p>
								</div>
								<div class="advertising_conten flex_disp cursorS">
									<div class="advertising_icon icon_image"
										@click="$router.push({path:'InFormationDetail',query:{id:contentList[1].id }})">
										<img :src="contentList[1].ne_img" style="object-fit: cover;" />
										<div class="advertising_box" v-if="contentList[1].ne_is_guanggao==1">
											<span>{{$t('newText.msg87')}}</span>
										</div>
									</div>
									<div class="advertising_icon icon_image" @click="$router.push('information')"
										:class="[cartoon[3]?'advertDh':'']" style="background-color: #CD6428;">
										<img src="../../assets/homelogols2.png" />
										<div class="advertising_text text_hovr  flex_disp">
											<span>{{$t("newWord.moreinform")}}</span>
											<i class="el-icon-right"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="information_all cursorS"
								@click="$router.push({path:'InFormationDetail',query:{id:contentListE[0].id }})">
								<span>{{$t('home.knowaBout')}}</span>
								<i class="el-icon-right"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="partner">
				<h3 class="partner_title">{{$t('home.partner')}}</h3>
				<div class="partner_box">
					<div class="partner_box_conten flex_disp" :style="'transform: translateX(-'+letIndex+'px);'">
						<div class="partner_icon_box" v-for="(item,index) in cooperation">
							<div class="partner_icon icon_image">
								<img :src="item">
							</div>
						</div>
					</div>
				</div>
				<div class="partner_box">
					<div class="partner_box_conten flex_disp" :style="'transform: translateX(-'+letIndex+'px);'">
						<div class="partner_icon_box" v-for="(item,index) in cooperationR">
							<div class="partner_icon icon_image">
								<img :src="item">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<countdown v-show="cartoon[2]"></countdown>
	</div>
</template>

<script>
	import countdown from '@/components/countDown.vue';
	import Header from '@/components/Header/header.vue';
	import Bottom from '@/components/bottom.vue';
	// @ is an alias to /src
	import CarouSel from "@/components/carousel.vue";
	import imageHover from '../../components/imageHover.vue';
	import Hiddsa from '@/components/jssss.vue';
	export default {
		name: "Home",
		components: {
			CarouSel,
			Header,
			Bottom,
			imageHover,
			Hiddsa,
			countdown
		},
		data() {
			return {
				cooperation: [
					require('@/assets/l01.png'),
					require('@/assets/l02.png'),
					require('@/assets/l03.png'),
					require('@/assets/l04.png'),
					require('@/assets/l05.png'),
					require('@/assets/l06.png'),
					require('@/assets/l07.png'),
				],
				cooperationR: [
					require('@/assets/l08.png'),
					require('@/assets/l09.png'),
					require('@/assets/l10.png'),
					require('@/assets/l11.png'),
					require('@/assets/l12.png'),
					require('@/assets/l13.png'),
					require('@/assets/l14.png'),
				],
				cing: {
					line: 14, //多少行
					column: 20, //多少列
					width: document.documentElement.clientWidth, //显示容器的宽度
					height: document.documentElement.clientHeight,
					windowWidth: document.documentElement.clientWidth,
					windowheight: document.documentElement.clientHeight,
					animeTime: 2,
					img: '' //图片路径
				},
				backIcon: '',
				windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
				windowHeight: document.documentElement.clientHeight, //实时屏幕高度
				showBtom: false,
				current: 0,
				homeCurrent: 0,
				throtTrue: true,
				positionList: [
					// {
					// src: require('@/assets/ces.mp4')
					// }
				],
				trackData: [{
						icon: require('@/assets/track01.png'),
						title: this.$t('newWord.peopleTile'),
						generalize: this.$t('newWord.peopleralize'),
						conten: this.$t('newWord.peopleConten')
					},
					{
						icon: require('@/assets/track02.png'),
						title: this.$t('newWord.HopeTitle'),
						generalize:  this.$t('newWord.Hoperalize'),
						conten:  this.$t('newWord.HopeConten')
					},
					{
						icon: require('@/assets/track03.png'),
						title: this.$t('newWord.figureTitle'),
						generalize: this.$t('newWord.figureralize'),
						conten: this.$t('newWord.figureConten')
					}
				],
				judgeList: [{},
					{},
					{},
				],
				firstNum: 0,
				offsetTop: 0,
				firstIF: true,
				expertSthink: [],
				imgStyle: '',
				timer: null,
				num: 10,
				intervalId: null,
				letIndex: 0,
				letNum: 0,
				rgNum: 6,
				cartoon: [false, false, false, false],
				cartoonShow: false,
				contentList: [],
				contentListE: [],
				offsetHeight: 0,
				qudaoShow: false,
				deviceShow: false,
				sceneShow: false,
				oemShow: false,
				productShow: false,
				timeShow: false,
				dixianShow: false,
				loading: false
			};
		},
		watch: {
			windowHeight(val) {
				let that = this;
				that.windowHeight = val;
			},
			windowWidth(val) {
				let that = this;
				that.windowWidth = val;
			},
			firstIF(value) {
				if (value) {
					this.$store.state.navHader = 0;
				} else {
					let height = this.$refs.headerH.offsetHeight;
					this.$store.state.navHader = height;
				}
			}
		},

		mounted() {
			let that = this;
			window.addEventListener("scroll", this.handleScroll);

			this.expert();
			this.getList();
			this.startRoll();
			this.newIndex(1);
			this.newIndex(2);
			if (localStorage.getItem("token")) {
				this.accountInformationObj();
			}
			if (this._isMobile()) {
				console.log(this._isMobile())
				that.windowHeight = 250;
			} else {
				console.log(this._isMobile())
				window.onresize = () => {
					return (() => {
						window.fullHeight = document.documentElement.clientHeight;
						window.fullWidth = document.documentElement.clientWidth;
						that.windowHeight = window.fullHeight; // 高
						that.windowWidth = window.fullWidth; // 宽
					})();
				};
			}
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll);
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)

				return flag;
			},
			handleScroll(e) { // 实现当滚动到指定位置，触发动画
				try {
					let scrollTop = document.documentElement.scrollTop;
					this.$nextTick(() => {
						// var id ="#synopsis"
						let synopsis0 = document.querySelector('#synopsis').offsetTop // 4116 元素距离顶部的距离
						let car_conten = document.querySelector('#car_conten').offsetTop
						let judge_box = document.querySelector('#judge_box').offsetTop;
						if (scrollTop >= synopsis0) {
							this.cartoon[0] = true;
						}
						if (scrollTop >= car_conten) {
							this.cartoon[1] = true;
						}
						if (scrollTop >= car_conten - 600) {
							this.cartoon[2] = true;
						} else {
							this.cartoon[2] = false;
						}
						if (scrollTop >= judge_box - 310) {
							this.cartoonShow = true;
						}
					})
				} catch (e) {
					//TODO handle the exception
				}

			},
			async accountInformationObj(val) {
				let _this = this
				try {
					let params = {}
					let res = await this.$axios.post('user/index', params);
					if (res.code == 1) {
						let data = res.data;
						let everyShow = data.user.is_one_edit == 0 ? true : false;
						localStorage.setItem('EveryShow', everyShow);
					}
				} catch (error) {}
			},
			startRoll() {
				if (this.intervalId != null) {
					return
				}
				let _this = this;
				let arr = [];
				let cooper = _this.cooperation;
				_this.intervalId = setInterval(function() {
					cooper.push(_this.cooperation[_this.letNum]);
					if (_this.rgNum == 0) {
						_this.rgNum = 6;
					} else {
						_this.rgNum--;
					}
					_this.cooperationR.push(_this.cooperationR[_this.rgNum]);
					_this.cooperation = cooper;
					_this.letNum++;
					_this.letIndex = parseInt(_this.letIndex) + 40;
				}, 500)
			},
			endRoll() {
				clearInterval(this.intervalId)
				this.intervalId = null
			},
			elChange(e) {
				this.homeCurrent = e;
			},
			toPinglun(text) {
				document.getElementById('synopsis_box').scrollIntoView({
					behavior: "smooth",
					block: "end",
					inline: "nearest"
				});
			},
			// toPinglun(text) {
			// 	this.$refs[text].scrollIntoView(true);
			// },
			async getList() {
				try {
					let data = {
						position_id: 7
					}
					let res = await this.$axios.post('ads/getList', data);
					if (res.code == 1) {
						res.data.forEach((item, index) => {
							if (item.videofile) {
								this.backIcon = 'https://cultural-creation.oss-cn-beijing.aliyuncs.com' + item
									.image;
								this.positionList.push({
									src: 'https://cultural-creation.oss-cn-beijing.aliyuncs.com' + item
										.videofile
								});
							} else {
								this.positionList.push({
									src: 'https://cultural-creation.oss-cn-beijing.aliyuncs.com' + item
										.image
								});
							}
						})
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			async expert() {
				try {
					let data = {
						year: 2022
					}
					let res = await this.$axios.post('index/expert', data);
					if (res.code == 1) {
						this.expertSthink = res.data;
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			async newIndex(index) {
				try {
					let _this = this
					let params = {
						page: 1,
						type: index==1?0:2
					}
					let res = await this.$axios.get('news/index', {
						params: params
					});
					if (res.code == 1) {
						console.log(res)
						if (index == 1) {
							this.contentList = res.data
							console.log(this.contentList)
						} else {
							this.contentListE = res.data;
						}
						// this.$router.push('personalCenter')
					}
				} catch (e) {
					//TODO handle the exception
				}
			}
		},

	};
</script>
