<template>
	<div class="index_box">
		<div class="background"></div>
		<div class="background_box">
			<div class="return_icon icon_image cursorS" @click.stop="goBack">
				<img src="@/assets/return.png">
			</div>
		</div>
		<Header :show="false"></Header>
		<router-view></router-view>
	</div>
</template>

<script>
	import Header from '@/components/Header/header.vue';
	export default {
		name: "index",
		components: {
			Header
		},
		methods: {
			goBack() {
				console.log(this.$route.name);
				if (window.history.length <= 1||this.$route.name=='Forinformation') {
					this.$router.push({
						path: '/'
					})
					return false
				} else {
					this.$router.go(-1)
				}
			}
		}
	}
</script>

<style>
	.index_box {
		width: 100%;
		height: 100%;
		/* position: relative; */
	}
	.background_box{
		width: 100%;
		height: 100%;
		position: relative;
	}
	.background {
		width: 100%;
		height: 100%;
		/**宽高100%是为了图片铺满屏幕 */
		z-index: -1;
		position: absolute;
		background-image: url("../../assets/loginBack.png");
		background-repeat: no-repeat;
		background-size: cover;
	}

	.return_icon {
		position: absolute;
		right: 364px;
		top: 154px;
		width: 46px;
		height: 46px;
		z-index: 9;
	}

	body {}
</style>
