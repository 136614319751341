<style lang="less">
	.whether_conten {
		width: 100%;
	}

	.bear {
		position: relative;

		.dateTite_box {
			width: 100%;
			position: relative;

			/deep/.el-date-table td.today span {
				color: #932224;
				font-weight: 700;
			}

			.el-year-table td.today .cell /deep/.el-date-table td.current:not(.disabled) span {
				background-color: #932224 !important;
			}

			/deep/.el-year-table td .cell:hover {
				color: #932224 !important;
			}
		}

		/deep/.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 100%;
		}

		/deep/.el-input__inner:focus {
			transition: all 1.4s linear 0s;
			border-color: #932224 !important;
		}

		/deep/.el-input__prefix .el-input__icon {
			display: none;
		}

		/deep/.el-input--prefix .el-input__inner {
			padding-left: 20px;
		}

		.el_Option {
			font-size: 22px !important;
		}

		/deep/.el-select-dropdown__item.selected {
			color: #932224 !important;
		}

		/deep/.el-select-dropdown__item.hover,
		/deep/.el-select-dropdown__item:hover {
			color: #932224 !important;
		}

		/deep/.el-input__inner {
			font-size: 20px;
			height: 58px !important;
			border-radius: 0;
		}

		/deep/.el-form-item__label {
			font-size: 22px !important;
			text-align: right;
			line-height: 58px;
			font-weight: 420;
		}

		/deep/.el-input {
			height: 55px;
		}

		>.bear_Add {
			width: 58px;
			height: 58px;
			position: absolute;
			right: 0;
			top: 29px;
		}

		>.bear_box {
			.basic_box {
				padding: 39px 117px 20px 0;
				background-color: #fff;
				margin-bottom: 20px;
				position: relative;

				>.baar_delete {
					position: absolute;
					width: 56px;
					height: 56px;
					right: -20px;
					top: -20px;
				}
			}
		}

	}

	.production {

		>.material,
		>.situation,
		>.bear {
			>h3 {
				text-align: left;
				font-size: 28px;
				padding: 35px 0;
			}

			padding-bottom:55px;
		}

		// 作品情况
		>.situation {

			>.situation_box {
				background-color: #fff;
				padding: 0 54px;

				>.material_box {
					text-align: left;
					padding: 30px 0;
					position: relative;

					>h3 {
						padding-top: 0px !important;
					}

					>.hint {
						font-size: 18px;
						color: #9D9D9D;
						text-align: left;
						padding-top: 15px;
						padding-left: 0;
					}

					>.material_input {
						width: 494px;
						height: 58px;
						margin-top: 30px;
						border: 1px solid #ccc;
						position: relative;
						padding: 0 23px;
						color: #9D9D9D;
						position: relative;

						>.material_icon {
							width: 44px;
							height: 44px;
						}
					}
				}

				>div {
					>h3 {
						padding-top: 40px;
						font-size: 28px;
						font-weight: 400;
					}
				}

				>.market,
				>.copyright {
					text-align: left;

					>.market_conten {
						padding: 50px 0;
					}
				}

				>h3 {
					font-size: 28px;
				}
			}
		}

		// 作品上传
		>.material,
		>.situation {
			>.material_box {
				background-color: #fff;
				font-size: 22px;
				padding: 10px 42px 49px 42px;
				position: relative;

				.download {
					// width: 278px;
					padding: 0 20px;
					max-width:318px;
					height: 58px;
					line-height: 58px;
					background: #932224;
					color: #fff;
					z-index: 2;
					margin-left: 15px;

					a {
						position: relative;
						z-index: 8;
					}
				}

				>.material_conten {
					padding-top: 39px;

					>.hint {
						font-size: 18px;
						color: #9D9D9D;
						text-align: left;
						padding-top: 15px;
						padding-left: 175px;
					}

					>div {
						position: relative;



						>.material_l {
							text-align: right;
							width: 162px;
							min-width: 162px;
							height: 100%;
							position: relative;

							>span {
								display: inline-block;
							}

							>p {
								font-size: 18px;
								color: #9D9D9D;
								position: absolute;
							}
						}

						>.material_input {
							border: 1px solid #ccc;
							width: 632px;
							height: 58px;
							margin: 0 20px;
							padding: 0 23px;
							color: #9D9D9D;
							position: relative;

							>.material_icon {
								width: 44px;
								height: 44px;
							}
						}
					}
				}
			}
		}
	}

	.photograph:hover {
		.preview {
			visibility: visible;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			padding: 60px 20px;
			text-align: center;
			background: rgba(0, 0, 0, 0.55);
			transform: rotateX(180deg);
			transition: all 0.50s ease-in-out 0s;

			>.preview_icon {
				opacity: 1;
			}
		}
	}

	.floating_anime {
		animation-name: puff-in-vertical;
		animation-duration: 1s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;

		@keyframes puff-in-vertical {
			0% {
				-webkit-transform: scaleY(2);
				transform: scaleY(2);
				filter: blur(4px);
				opacity: 0;
			}

			100% {
				-webkit-transform: scaleY(1);
				transform: scaleY(1);
				filter: blur(0);
				opacity: 1;
			}
		}

	}

	.photograph {
		width: 274px;
		height: 194px;
		margin-left: 15px;
		position: relative;
		overflow: initial !important;
		background: #F5F5F5;
		animation-name: shutter-in-right;
		animation-duration: 1s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;
		margin-bottom: 16px;

		.photograph_text {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 30px;
			font-size: 18px;
			font-weight: bold;
		}

		@keyframes shutter-in-right {
			0% {
				-webkit-transform: rotateY(-100deg);
				transform: rotateY(-100deg);
				-webkit-transform-origin: right;
				transform-origin: right;
				opacity: 0;
			}

			100% {
				-webkit-transform: rotateY(0);
				transform: rotateY(0);
				-webkit-transform-origin: right;
				transform-origin: right;
				opacity: 1;
			}
		}

		>.preview {
			transform: rotateX(0);
			justify-content: center;
			align-items: center;
			visibility: hidden;
			position: absolute;
			pointer-events: none;

			>.preview_icon {
				width: 34px;
				height: 24px;
				opacity: 0;
				z-index: 20;
			}
		}

		>.avatar {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}

		.photograph_conten {
			width: 194px;
			margin: 0 auto;
			font-size: 12px;

			>.photograph_img {
				width: 90px;
				height: 90px;
				margin: 35px auto 17px auto;
			}
		}

		>.photographDele {
			width: 45px;
			height: 45px;
			position: absolute;
			top: -20px;
			right: -20px;
			z-index: 9;

		}
	}

	.eb_box {
		width: 334px;
		height: 66px;
	}

	.eb_conten {
		width: 100%;
		align-items: center;
		justify-content: center;
		margin-top: 74px;

		>div {
			margin-right: 20px;
			width: 334px !important;
			height: 66px !important;
		}
	}

	.forinformation {

		color: #000;

		.el-input__inner:focus {
			transition: all 1.4s linear 0s;
			border-color: #932224 !important;
		}

		.el_Option {
			font-size: 22px !important;
		}

		.el-select-dropdown__item.selected {
			color: #932224 !important;
		}

		.el-select-dropdown__item {}

		.el-select-dropdown__item.hover,
		.el-select-dropdown__item:hover {
			color: #932224 !important;
		}

		.el-input__inner {

			font-size: 20px;
			height: 58px !important;
			border-radius: 0;
		}

		.el-form-item__label {
			font-size: 22px;
			text-align: right;
			line-height: 58px;
			font-weight: 420;
		}

		.el-input {
			height: 55px;
		}

		.basic {

			.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
			.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
				content: '' !important;
				color: #F56C6C;
				margin-right: 0 !important;
			}

			.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
			.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:after {
				content: '*' !important;
				color: #F56C6C !important;
				margin-right: 0.25rem;
			}

			>h3 {
				font-size: 28px;
				padding-bottom: 35px;
				text-align: left;
			}

			>.basic_box {
				background-color: #fff;
				padding: 75px 145px 25px 0;
			}
		}

		.describe {
			padding-top: 90px;

			.keywordinfuse {
				color: #9D9D9D;
				font-size: 18px;
				padding-left: 10px;
			}

			>.antistop {
				padding: 37px 35px 102px 35px;
				background-color: #fff;

				>div {
					font-size: 20px;
					margin-right: 20px;
					position: relative;

					.photographDele {
						width: 40px;
						height: 40px;
						position: absolute;
						top: -20px;
						right: -20px;
					}
				}

				>.antistop_box {
					border: 1px solid #979797;
					color: #9D9D9D;
					padding: 15px 28px;
					justify-content: center;

					>span {
						padding-right: 18px;
					}
				}
			}

			>.describe_box {
				.el-textarea {
					background-color: #fff;
					font-size: 22px;
					color: #9D9D9D;

					.el-textarea__inner {
						padding: 40px 44px;
						border-color: #fff;
						min-height: 400px !important;
					}
				}
			}
		}

		.singleTree {
			padding: 35px 47px;
			font-size: 20px;

			>.singleTree_box {
				width: 100%;
				text-align: center;

				>span {
					display: inline-block;
					flex: 1;
				}

				>span:nth-child(1) {
					text-align: left;
				}
			}

			>.singleTree_conten {
				padding-top: 36px;
				align-items: flex-start;

				>div {
					flex: 1;

					>p {
						padding: 12px 0;
					}
				}

				>div:nth-child(1) {
					text-align: left;
				}
			}
		}
	}

	.eb_conten {
		width: 100%;
		align-items: center;
		justify-content: center;
		margin-top: 74px;

		>div {
			margin-right: 20px;
			width: 334px !important;
			height: 66px !important;
		}
	}

	.diang_box {
		width: 50%;
		margin: 0 auto;
	}

	.successfully {
		background-color: #FFFFFF;
		padding: 30px 0;

		>.successfully_icon {
			width: 78px;
			height: 78px;
			margin: 83px auto 0px auto;
		}

		text-align: center;

		.successfully_infuse {
			font-size: 22px;
			color: #932224;
			padding: 60px 0;
		}

		>.successfully_btn {
			font-size: 22px !important;

			div {
				margin: 0 auto 10px auto;
				width: 494px;
				height: 58px;
			}

			.successfully_btn_conten {
				border: 1px solid #979797;
				line-height: 58px;
			}
		}

		>h2 {
			font-size: 38px;
			padding: 42px 0 96px 0;
		}
	}

	.everyBtn {
		width: 450px;
		margin: 0 auto;

		div {
			width: 212px;
			height: 66px;
		}

		>.everyBtn_conten {
			justify-content: center;
			border: 1px solid #A8A8A8;
		}
	}

	.foruploading_step {
		justify-content: center;
		margin-top: 13px;

		>.step_box {
			color: #9D9D9D;
			font-size: 22px;
			margin-left: 10px;

			>.step_serial {
				width: 36px;
				height: 36px;
				border: 1px solid #979797;
				border-radius: 50%;
				justify-content: center;
			}

			>span {
				display: inline-block;
				padding: 0 10px;
			}

			>.step_color {
				color: #CD6428;
			}

			>.step_serialColor {
				background-color: #CD6428;
				color: #fff;
				border: 1px solid #CD6428;
			}

			>p {
				width: 64px;
				height: 1px;
				display: inline-block;
				background-color: #979797;
			}

			>.solid_color {
				background-color: #CD6428;
				transition: all 1s linear 0s;
			}
		}
	}

	.foruploading_step {
		padding-bottom: 60px;
	}

	.jxbc {
		background-color: #932224;
		color: #FFFFFF;
		line-height: 58px;
	}

	.basic_hiden {
		text-align: left;
		color: #F56C6C;
		font-size: 12px;
		padding-top: 1px;
	}
	.loader_box{
		position: absolute;
		left: 43%;
		top: 40%;
		transform: translate(-50%,-50%);
	    width: 48px;
	
	    height: 48px;
	
	    border: 5px solid #FFF;
	
	    border-bottom-color: #FF3D00;
	
	    border-radius: 50%;
	
	    display: inline-block;
	
	    -webkit-animation: rotation 1s linear infinite;
	
	    animation: rotation 1s linear infinite;
	}
	@keyframes rotation {
		0% {
			
		  transform: rotate(0deg);
			
		}
			
		100% {
			
		  transform: rotate(360deg);
			
		}
	}
</style>
<template>
	<div class="forinformation">
		<div class="foruploading_step flex_disp">
			<div class="step_box flex_disp" v-for="(item,index) in $t('foruploading.step')" :key="index">
				<div class="step_serial flex_disp" :class="[index<schedule+1?'step_serialColor':'']">{{index+1}}</div>
				<span :class="[index<schedule+1?'step_color':'']">{{item}}</span>
				<p v-show="index<2" :class="[index+1<schedule+1?'solid_color':'']"></p>
			</div>
		</div>
		<div v-show="schedule==0">
			<el-form :model="material" :rules="basicRules" ref="basicFrom" class="demo-ruleForm">
				<div class="basic">
					<h3>{{$t('forinformation.basicsName')}}<span class="sign_app_text">*</span></h3>
					<div class="basic_box">
						<el-form-item>
							<el-col :span="12">
								<el-form-item label-width="8.98438vw" prop="en_name"
									:label="$t('forinformation.basics.authorName')">
									<FormInput :fatherValue.sync="material.en_name" :maxlength="20"
										:iValue="material.en_name"
										:matter="new $Custom($t('forinformation.basics.authorInput'))" name="name">
									</FormInput>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label-width="8.98438vw" prop="selectModel"
									:label="$t('forinformation.basics.take')">
									<el-select :editable="false" v-model="material.selectModel"
										:popper-append-to-body="false" style="width: 100%;"
										:placeholder="$t('forinformation.basics.takeInput')" size="small" clearable
										:filter-method="singleTreeFilterMethod" ref="select"
										popper-class="customSelectPopper">
										<el-option :editable="false" v-for="item in matchList" :visible-change="visible"
											:key="item.id" :label="item.label" :value="item.id" v-show="false">
										</el-option>
										<div class="singleTree">
											<div class="singleTree_box flex_just_disp">
												<span>{{$t("newText.msg48")[0]}}</span>
												<span>{{$t("newText.msg48")[1]}}</span>
											</div>
											<div class="singleTree_conten flex_just_disp" v-if="matchList">
												<div class="singleTree_left">
													<p class="cursorS" v-for="(item,index) in matchList"
														@click.stop="matindex=index;"
														:style="matindex==index?'color:#932224':''">
														{{item.en_channel_name}}
													</p>
												</div>
												<div class="singleTree_rtg">
													<p class="cursorS" v-for="(item,index) in matchList[matindex].theme"
														:key="index" @click.stop="trackTap(item)" :style="material.en_theme_id==item.id?'color:#932224':
													''">{{item.en_theme}}</p>
												</div>
											</div>
										</div>
									</el-select>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item>
							<el-col :span="12">
								<!-- 作品分类 -->
								<el-form-item label-width="8.98438vw" prop="en_category_id"
									:label="$t('forinformation.basics.classifyName')">
									<!-- <el-select v-model="material.className" :popper-append-to-body="false"
										style="width: 100%;" :placeholder="$t('forinformation.basics.takeInput')"
										size="small" clearable filterable :filter-method="singleTreeFilter"
										ref="select" popper-class="customSelectPopper">
										<el-option v-for="item in entrList" :visible-change="visible" :key="item.id"
											:label="item.name" :value="item.id" v-show="false">
										</el-option>
										<div class="singleTree">
											<div class="singleTree_box flex_just_disp">
												<span>大类</span>
												<span>小类</span>
											</div>
											<div class="singleTree_conten flex_just_disp" v-if="entrList">
												<div class="singleTree_left" style="flex: 1;text-align: left;">
													<p v-for="(item,index) in entrList" class="text_1_size" @click.stop="entrIndex=index;"
														:style="entrIndex==index?'color:#932224':''">
														{{item.name}}
													</p>
												</div>
												<div class="singleTree_rtg" style="flex: 1;text-align: right;">
													<p v-for="(item,index) in entrList[entrIndex].childlist" :key="index"
														@click.stop="trackTap(item)" class="text_1_size" :style="material.className==item.name?'color:#932224':
													''">{{item.name}}</p>
												</div>
											</div>
										</div>
									</el-select> -->
									<el-select v-model="material.className" @change="classifyChange"
										:popper-append-to-body="false" style="width: 100%;" ref="classSlese"
										:placeholder="$t('forinformation.basics.classifyInput')">
										<el-option :value="material.className" style="height: auto">
											<el-tree :data="entrList" accordion highlight-current
												@node-click="handleNodeClick">
											</el-tree>
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label-width="8.98438vw" prop="author_num"
									:label="$t('forinformation.basics.authorNum')">
									<el-select v-model="material.author_num" @change="authorNum"
										:popper-append-to-body="false" style="width: 100%;"
										:placeholder="$t('forinformation.basics.authorNumInput')">
										<el-option v-for="item in $t('forinformation.basics.authorNumArr')"
											style="font-size: 0.97656vw;" class="el_Option" :key="item" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item>
							<el-col style="padding-bottom: 1.17188vw;" :span="12" v-for="(item,index) in material.author"
								:key="index">
								<el-form-item label-width="8.98438vw" :label="$t('forinformation.basics.authorArr')[index]"
									:prop="`author.${index}`"
									:rules="{ required: true, message:$t('forinformation.basics.authorNameArr'), trigger: 'change' }">
									<el-input :placeholder="$t('forinformation.basics.authorNameArr')"
										v-model="material.author[index]" clearable>
									</el-input>
								</el-form-item>
							</el-col>
						</el-form-item>
					</div>
				</div>
				<div class="basic describe">
					<h3>{{$t('forinformation.describe')}}<span class="sign_app_text">*</span></h3>
					<div class="describe_box">
						<el-form-item prop="en_describe">
							<el-input type="textarea" :rows="2" maxlength="200"
								:placeholder="$t('forinformation.basics.describeConten')"
								v-model="material.en_describe">
							</el-input>
						</el-form-item>
					</div>
				</div>
				<div class="basic describe">
					<!-- 作品关键字 -->
					<h3>{{$t('forinformation.keywordName')}}<span class="sign_app_text">*</span><span
							class="keywordinfuse">{{$t('forinformation.basics.keywordinfuse')}}</span></h3>
					<div class="antistop flex_disp">
						<div class="flex_disp floating_hovr" style="height: 3.25521vw"
							v-for="(item,index) in material.en_tag" key="index">
							<Ebutton :text="item" :maxlength="20" class="floating_anime" style="padding: 0 0.65104vw;">
							</Ebutton>
							<div class="photographDele icon_image" @click.stop="material.en_tag.splice(index,1)">
								<img src="../../assets/imgdele.png">
							</div>
						</div>
						<div v-if="material.en_tag.length<3" class="antistop_box flex_disp floating_hovr"
							@click.stop="dialogShow=true">
							<span>{{$t('forinformation.custom')}}</span>
							<i class="el-icon-plus"></i>
						</div>
					</div>
					<p class="basic_hiden" v-if="basic_hiden">{{$t("newText.msg49")}}</p>
				</div>
			</el-form>
		</div>
		<!-- 上传作品资料 -->
		<div class="production" v-show="schedule==1">
			<div class="material">
				<h3>{{$t("newText.msg50")}}</h3>
				<div class="material_box">
					<div class="material_conten">
						<div class="flex_disp ">
							<div class="material_l">
								<span>{{$t("newText.msg51")}}</span>
							</div>
							<div class="download cursorS" @click="loadTemplate">
								{{$t('forinformation.data.template')}}
							</div>
						</div>
						<p class="hint">{{$t("newText.msg52")}}</p>
					</div>
					<div class="material_conten">
						<div class="flex_disp floatingBoder_hovr" style="flex-wrap: nowrap;">
							<div class="material_l">
								<span>{{$t('forinformation.data.pictureTitle')}}<span
										class="sign_app_text">*</span></span>
								<!-- <p>{{$t('forinformation.data.watch')}}</p> -->
							</div>
							<!-- <div class="material_input floatingBoder_hovr flex_just_disp"
								v-if="material.en_images.length===0" @click.stop="!download?everyShow=true:''">
								<UpLoading @success="ImgSuccess" v-if="download"></UpLoading>
								<span>{{$t('forinformation.data.pictureExplain')}}</span>
								<div class="material_icon icon_image">
									<img src="@/assets/productionicon1.png">
								</div>
							</div> -->
							<!-- v-if="material.en_images.length>0 " -->
							<div class="flex_disp">
								<div class="photograph icon_image" v-for="(item,index) in material.en_images"
									:key="index">
									<el-image style="width: 100%; height: 100%" :src="item"
										:preview-src-list="material.en_images" fit="scale-down">
									</el-image>
									<div class="photographDele icon_image" @click.stop="deleimage(index)">
										<img src="../../assets/imgdele.png">
									</div>
									<div class="preview flex_disp">
										<div class="preview_icon icon_image">
											<img src="../../assets/yj.png">
										</div>
									</div>
								</div>
								<!-- 主图 -->
								<div class="photograph icon_image" v-if="!emgSucce"
									@click.stop="!download?everyShow=true:''">
									<img src="../../assets/imgAdd.png">
									<span class="photograph_text">{{$t("newText.msg53")}}</span>
									<UpLoading @success="EmgSuccess" v-if="download"></UpLoading>
								</div>
								<!-- 副图 -->
								<div class="photograph icon_image"
									@click="material.en_images.length===0?$message.error({message:aa,offset:120}):''"
									v-if="material.en_images.length<6">
									<img src="../../assets/imgAdd.png">
									<span class="photograph_text">{{$t("newText.msg55")}}</span>
									<UpLoading @success="ImgSuccess" v-if="material.en_images.length>0"></UpLoading>
								</div>
							</div>
						</div>
						<p class="hint">{{$t('forinformation.data.pictureHint')}}
						</p>
					</div>
					<div class="material_conten">
						<div class="flex_disp ">
							<div class="material_l">
								<span>{{$t('forinformation.data.videoTitle')}}</span>
							</div>
							<!-- <div class="material_input floatingBoder_hovr flex_just_disp" v-if="!material.en_video">
							
								<span>{{$t('forinformation.data.videoExplain')}}</span>
								<div class="material_icon icon_image">
									<img src="@/assets/productionicon2.png">
								</div>
							</div> -->
							<div class="photograph" v-if="material.en_video">
								<video v-if="material.en_video!=''" :src="material.en_video" class="avatar video-avatar"
									controls="controls">
									{{$t("newText.msg56")}}
								</video>
								<div class="photographDele icon_image" @click.stop="material.en_video=''">
									<img src="../../assets/imgdele.png">
								</div>
							</div>
							<div class="photograph icon_image" v-if="!material.en_video">
								<template v-if="loaderShow"><div class="loader_box"></div></template>
								<template v-else>
									<img src="../../assets/imgAdd.png">
									<span class="photograph_text">{{$t("newText.msg57")}}</span>
								</template>
								<UpLoading @success="videoChange" @upload="upload" name="video"></UpLoading>
							</div>
						</div>
						<p class="hint">{{$t('forinformation.data.videoHint')}}</p>
					</div>
					<div class="material_conten ">
						<div class="flex_disp">
							<div class="material_l">
								<span>{{$t('forinformation.data.documentTitle')}}</span>
							</div>
							<!-- <div class="material_input floatingBoder_hovr flex_just_disp"
								v-if="material.en_explain.length==0">
								<span>{{$t('forinformation.data.documentExplain')}}</span>
								<div class="material_icon icon_image">
									<img src="@/assets/productionicon3.png">
								</div>
								<UpLoading @success="getpptHttp" name="PPT"></UpLoading>
							</div> -->
							<!-- v-if="material.en_explain.length>0 " -->
							<div class="flex_disp">
								<div class="photograph cursorS icon_image" v-for="(item,index) in material.en_explain"
									:key="index">
									<div class="photograph_conten cursorS"
										@click.stop="item.url?downloadChange(item.url):downloadChange(item)">
										<div class="photograph_img icon_image">
											<img src="../../assets/filepdf.png">
										</div>
										<p class="text_2_size">{{item.name}}</p>
									</div>
									<div class="photographDele icon_image"
										@click.stop="material.en_explain.splice(index,1)">
										<img src="../../assets/imgdele.png">
									</div>
									<!-- <div class="preview flex_disp">
										<div class="preview_icon icon_image"
											@click="item.url?downloadChange(item.url):downloadChange(item)">
											<img src="../../assets/yj.png">
										</div>
									</div> -->
								</div>
								<div class="photograph icon_image" v-if="material.en_explain.length<1">
									<img src="../../assets/imgAdd.png">
									<span class="photograph_text">{{$t("newText.msg58")}}</span>
									<UpLoading @success="getpptHttp" name="PDF"></UpLoading>
								</div>
							</div>
						</div>
						<p class="hint">{{$t('forinformation.data.documentHint')}}</p>
					</div>
					<div class="material_conten">
						<div class="flex_disp">
							<div class="material_l">
								<span>{{$t('forinformation.data.modelingTitle')}}</span>
							</div>
							<!-- <div class="material_input floatingBoder_hovr flex_just_disp"
								v-if="material.en_modeling.length==0">
								<span>{{$t('forinformation.data.modelingExplain')}}</span>
								<div class="material_icon icon_image">
									<img src="@/assets/productionicon3.png">
								</div>
								<UpLoading @success="getWordHttp" name="WORD"></UpLoading>
								v-if="material.en_modeling.length>0 "
							</div> -->
							<div class="flex_disp">
								<div class="photograph cursorS icon_image" v-for="(item,index) in material.en_modeling"
									:key="index">
									<div class="photograph_conten"
										@click.stop="item.url?downloadChange(item.url):downloadChange(item)">
										<div class="photograph_img icon_image">
											<img src="../../assets/dofile.png">
										</div>
										<p class="text_2_size">{{item.name}}</p>
									</div>
									<div class="photographDele icon_image" @click.stop="delemode(index)">
										<img src="../../assets/imgdele.png">
									</div>
									<!-- <div class="preview flex_disp">
										<div class="preview_icon icon_image">
											<img src="../../assets/yj.png">
										</div>
									</div> -->
								</div>
								<div class="photograph icon_image" v-if="material.en_modeling.length<1">
									<img src="../../assets/imgAdd.png">
									<span class="photograph_text">{{$t("newText.msg58")}}</span>
									<UpLoading @success="getWordHttp" name="WORD"></UpLoading>
								</div>
							</div>
						</div>
						<p class="hint">{{$t('forinformation.data.modelingHint')}}</p>
					</div>
				</div>
			</div>
			<!-- 作品情况 -->
			<div class="situation">
				<h3>{{$t("newText.msg59")}}</h3>
				<div class="situation_box">
					<!-- 销售情况 -->
					<div class="market">
						<!-- material.en_video -->
						<h3>{{$t("newText.msg60")}}<span class="sign_app_text">*</span></h3>
						<div class="whether_conten">
							<Whether :whether="$t('forinformation.data.marketArr')" @whetherClick="whetherClick"
								:radio="material.en_situation"></Whether>
						</div>
					</div>
					<div class="copyright">
						<h3 style="padding-top: 0;">{{$t('forinformation.data.copyrightName')}}<span
								class="sign_app_text">*</span> </h3>
						<Whether :whether="$t('forinformation.data.copyrightArr')" @whetherClick="copyrightClick"
							:radio="material.en_copyright"></Whether>
					</div>
					<div class="material_box" v-if="material.en_copyright==1">
						<h3 style="padding-bottom: 1.30208vw; display: inline-block;">{{$t('forinformation.data.explain')}}
						</h3>
						<div class="photograph icon_image" v-if="material.en_copyright_file">
							<el-image style="width: 100%; height: 100%" :src="material.en_copyright_file"
								:preview-src-list="[material.en_copyright_file]" fit="scale-down">
							</el-image>
							<div class="photographDele icon_image cursorS" @click.stop="deleLain()">
								<img src="../../assets/imgdele.png">
							</div>
							<div class="preview flex_disp">
								<div class="preview_icon icon_image">
									<img src="../../assets/yj.png">
								</div>
							</div>
						</div>
						<div class="material_input floatingBoder_hovr flex_just_disp"
							v-if="!material.en_copyright_file">
							<!-- <span>{{$t('forinformation.data.pictureExplain')}}</span> -->
							<span>{{$t('forinformation.data.explaClick')}}</span>
							<div class="material_icon icon_image">
								<img src="@/assets/productionicon1.png">
							</div>
							<UpLoading @success="getexplainImg" name="image"></UpLoading>
						</div>
						<p class="hint">{{$t('forinformation.data.explaPmx')}}</p>
					</div>

				</div>
			</div>
			<div class="bear">
				<h3>{{$t("newText.msg61")}}</h3>
				<div class="bear_Add icon_image cursorS" @click.stop="bearAdd($t('forinformation.bear')[0])">
					<img src="@/assets/productionAdd.png">
				</div>
				<div class="bear_box">
					<div class="basic_box" v-for="(item,index) in situation" :key="index">
						<div class="baar_delete icon_image cursorS" v-if="index!=0" @click.tap="bearDele(index)">
							<img src="@/assets/productionDele.png">
						</div>
						<el-form class="demo-ruleForm">
							<el-form-item>
								<el-col :span="12">
									<el-form-item label-width="8.98438vw" prop="region"
										:label="$t('forinformation.bear').bearName">
										<el-input :placeholder="$t('forinformation.bear').bearName"
											v-model="situation[index].en_name" clearable maxlength="30">
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label-width="8.98438vw" prop="region"
										:label="$t('forinformation.bear').bearX">
										<el-input :placeholder="$t('forinformation.bear').xInput"
											v-model="situation[index].en_prize" clearable maxlength='20'>
										</el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item class="cursorS">
								<el-col :span="12">
									<el-form-item class="cursorS" label-width="8.98438vw" prop="date1"
										:label="$t('forinformation.bear').bearTime">
										<div class="dateTite_box cursorS">
											<el-date-picker style="width:100%" class="cursorS" type="date"
												:placeholder="$t('forinformation.bear').timeInput" :editable="false"
												v-model="situation[index].reward_time"
												:picker-options="pickerBeginDateBefore" value-format="timestamp">
											</el-date-picker>
										</div>
									</el-form-item>
								</el-col>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<div class="successfully" v-show="schedule==2">
			<div class="successfully_icon icon_image">
				<img src="../../assets/successfully.png">
			</div>
			<h2>{{material.en_check_state=='0'?$t("newText.msg62"):$t("newText.msg63")}}</h2>
			<p class="successfully_infuse">{{$t("newText.msg64")}}</p>
			<div class="successfully_btn">
				<div class="jxbc cursorS" @click="toUrl">
					<!-- <Ebutton @btnTap="toUrl" text="继续补充作者信息"></Ebutton> -->
					<p>{{$t("newText.msg65")}}</p>

				</div>
				<div class="successfully_btn_conten cursorS" @click="goGexz"><span>{{$t("newText.msg66")}}</span></div>
			</div>
		</div>
		<div class="eb_conten flex_disp" v-if="schedule!=2">
			<div class="eb_conten_box" v-show="schedule!=0">
				<!-- 保存 -->
				<Ebutton @btnTap="lastStep" :text="$t('newText.msg67')"></Ebutton>
			</div>
			<div class="eb_conten_box" v-show="schedule==0&&elrbtn">
				<!-- 保存 -->
				<Ebutton @btnTap="fromSave" :text="$t('newText.msg68')"></Ebutton>
			</div>
			<div class="eb_conten_box" v-show="schedule!=0&&schedule!=3&&elrbtn">
				<!-- 保存 -->
				<Ebutton @btnTap="fromSave" :text="$t('newText.msg68')"></Ebutton>
			</div>
			<div class="eb_conten_box" v-show="schedule!=1">
				<!-- 保存 -->
				<Ebutton @btnTap="nextStep" :text="$t('newText.msg69')"></Ebutton>
			</div>
			<div class="eb_conten_box" v-show="schedule==1">
				<!-- 保存 -->
				<Ebutton @btnTap="nextStep" :text="$t('newText.msg70')"></Ebutton>
			</div>
		</div>
		<Dialog :show="dialogShow" :title="$t('forinformation.keyName')" @closeBindWarnStandard="close">
			<template v-slot:content>
				<div class="diang_box">
					<div class="diang_input">
						<el-form @submit.native.prevent>
							<el-form-item>
								<el-input :placeholder="$t('forinformation.keyWordInput')" v-model="keyWord"
									maxlength="20">
								</el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="eb_conten flex_disp">
						<div class="eb_conten_box">
							<!-- 保存 -->
							<Ebutton @btnTap="cruxQue" :text="$t('newText.msg71')"></Ebutton>
						</div>
					</div>
				</div>
			</template>
		</Dialog>
		<Dialog :show="everyShow" :title="$t('newText.msg72')" @closeBindWarnStandard="close">
			<template v-slot:foot>
				<div class="everyBtn flex_just_disp">
					<div class="everyBtn_conten flex_disp" @click="everyShow=false">{{$t('newText.msg73')}}</div>
					<div>
						<Ebutton @btnTap="loadTemplate" :text="$t('newText.msg74')"></Ebutton>
					</div>
				</div>
			</template>
		</Dialog>
	</div>
</template>

<script>
	import FormInput from '@/components/ele/formInput.vue';
	import Ebutton from '@/components/ele/elButton.vue';
	import Whether from '@/components/whetherRadio.vue';
	import UpLoading from '@/components/ele/uploading.vue';
	import Dialog from '@/components/dialog.vue';
	export default {
		components: {
			FormInput,
			Ebutton,
			Whether,
			UpLoading,
			Dialog
		},
		data() {
			var checkAge = (rule, value, callback) => {
				if (!value) {
					return callback(new Error(this.$t('newText.msg75')));
				}
				setTimeout(() => {
					if (!Number.isInteger(value)) {
						callback(new Error(this.$t('newText.msg76')));
					} else {
						if (value < 18) {
							callback(new Error(this.$t('newText.msg77')));
						} else {
							callback();
						}
					}
				}, 1000);
			};
			return {
				elrbtn: true,
				aa: this.$t("newText.msg54"),
				mineStatusValue: '',
				pickerBeginDateBefore: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					}
				},
				loaderShow:false,
				entrIndex: 0,
				download: false,
				shopId: "",
				everyShow: false,
				forinformationValue: [{

				}],
				situation: [{
					en_name: '',
					en_prize: '',
					reward_time: ''
				}],
				material: {
					// id
					id: this.$route.query.id,
					situation: [{
						en_name: '',
						en_prize: '',
						reward_time: ''
					}],
					// 作品名称
					en_name: '',
					// 参赛的名称
					selectModel: '',
					//参赛分类名称
					className: '',
					// 	参赛主题
					en_theme_id: '',
					// 	报错或提交 0保存/1提交
					en_check_state: '1',
					// 分类
					en_category_id: '',
					// 	作品人数
					author_num: '',
					// 作品描述
					en_describe: '',
					// 介绍视频
					en_video: '',
					// 作品情况 1处于概念期 2无上市销售经历和计划 3有上市销售计划 4正在上市销售 5已停售’
					en_situation: '',
					// ‘是否进行过版权登记 0否 1是’
					en_copyright: '-1',
					// 版权书 如果版权登记为是 必选
					en_copyright_file: '',
					// 版权登记图片
					explainImg: [],
					// 作品图片
					en_images: [],
					// 建模文档
					en_modeling: [],
					// 说明文档
					en_explain: [],
					// 作品关键字
					en_tag: [],
					// 作者集合
					author: []
				},
				basicRules: {
					en_name: [{
							required: true,
							message: this.$t('forinformation.basics.authorInput'),
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '在 1 到 20 个字符',
							trigger: 'blur'
						}
					],
					selectModel: [{
						required: true,
						message: this.$t('forinformation.basics.takeInput'),
						trigger: 'change'
					}],
					en_category_id: [{
						required: true,
						message: this.$t('forinformation.basics.classifyInput'),
						trigger: ['blur', 'change']
					}],
					author_num: [{
						required: true,
						message: this.$t('forinformation.basics.authorNumInput'),
						trigger: ['blur', 'change']
					}],
					en_describe: [{
						required: true,
						message: this.$t('forinformation.basics.describeRuls'),
						trigger: 'blur'
					}],
					author: [{
						validator: checkAge,
						trigger: 'blur'
					}],
					// {
					// 	required: true,
					// 	message: this.$t('forinformation.basics.authorNameArr'),
					// 	trigger: 'change'
					// }
				},
				// 作品上市销售情况
				en_situation: 1,
				// 是否版权登记过 1登记 0 未登记
				en_copyright: 0,
				// 0保存/1提交
				en_check_state: '1',
				// 赛道id
				en_theme_id: '',
				// en_copyright_file
				dialogShow: false,
				// 基本信息
				keyWord: '',
				keyMode: [{
					required: true,
					message: this.$t('forinformation.keyWordInput'),
					trigger: 'blur'
				}],
				authorIndex: 1,
				basic: {
					// 作品名称
					name: '',
					selectModel: '',
					authorIndex: 1,
					// 作品大类
					classify: '',
					describeText: '',
					authorInput: ['']
				},

				// 进度
				schedule: 0,
				ruleForm: {},
				rules: [],
				value: '',
				region: [{
					required: true,
					message: '请选择活动区域',
					trigger: 'change'
				}],
				// 作品描述
				describeText: '',
				// 自定义下拉框树
				selectModel: '',
				defaultExpandedkeys: [],
				entrList: '',
				matchList: '',
				matindex: 0,
				visible: true,
				whetherIndex: 0,
				copyrightIndex: 0,
				dataTime: '',
				worksIcon: [],
				videoSrc: '',
				antistopArr: [],
				url: 'http://39.103.217.231',
				emgSucce: '',
				basic_hiden: false

			}
		},
		created() {
			this.entriesCate();
			// 赛道
			this.matchIndex();
		},
		mounted() {
			this.$nextTick(() => {
				this.$refs["basicFrom"].clearValidate()
			})
			if (this.$route.query.id) {
				this.entriesDetail(this.$route.query.id);
			} else {
				this.getTempEntries();
			}
			window.addEventListener('scroll', this.scrollToTop);
			this.$axios.post('user/index').then((res)=>{
				if(res.code===1){
					if(res.data.user.is_xiazai001==1){
						this.download=true;
					}
				}
			})
		},
		destroyed() {
			window.removeEventListener('scroll', this.scrollToTop)
		},
		beforeUpdate() {

		},
		watch: {
			schedule(value) {
				this.$number = value;
			}
		},
		methods: {
			upload(is){
				this.loaderShow=is;
			},
			async getTempEntries() {
				try {
					let res = await this.$axios.post('entries/getTempEntries');
					if (res.code == 1) {
						let data = res.data.material;
						if (res.data.situation.length >= 1) {
							this.situation = res.data.situation;
						}
						if (res.data.emgSucce) {
							this.emgSucce = res.data.emgSucce;
						}
						Object.keys(data).forEach((item, index) => {
							Object.keys(this.material).forEach((i, idx) => {
								if (item == i) {
									this.material[i] = data[item];
								}
							})
						})
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			// 下载链接
			async is_xiazai() {
				await this.$axios.post('user/is_xiazai',{user_id:localStorage.getItem('USERID')});
			},
			authorNum(e) {
				let num = 0;
				if (this.material.author.length < e) {
					let xunNum = e - this.material.author.length;
					for (let i = 0; i < xunNum; i++) {
						this.material.author.push('');
					}
				} else {
					let num = this.material.author.length - e;
					this.material.author.splice(e - 1, num);
				}
			},
			async loadTemplate() {
				try {
					let res = await this.$axios.post('entries/loadTemplate');
					if (res.code == 1) {
						this.download = true;
						this.everyShow = false;
						this.downloadChange(res.data);
						localStorage.setItem('download', this.download);
						this.is_xiazai();
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			goGexz() {
				this.$router.push('/personalCenter');
				localStorage.setItem('headIndex', 1);
			},
			downloadChange(url) {
				var iframe = document.createElement("iframe")
				iframe.style.display = "none";
				iframe.src = url;
				document.body.appendChild(iframe);
			},
			// 删除图片
			deleimage(index) {
				this.material.en_images.splice(index, 1);
				if (index == 0) {
					this.emgSucce = '';
				}
			},
			delemode(index) {
				this.material.en_modeling.splice(index, 1);
			},
			deleLain(index) {
				this.material.en_copyright_file='';
				// this.material.en_explain.splice(index, 1);
			},
			toUrl() {
				this.$router.push({
					name: "AddAuthor",
					query: {
						number: this.material.author_num,
						types: 1,
						id: this.shopId,
						goBack: 0
					}
				})
			},
			/**
			 * select单选下拉树自定义搜索
			 * @param {Object} val
			 */
			singleTreeFilterMethod(val) {
				this.$refs.selectTree.filter(val);
			},
			singleTreeFilter() {
				this.$refs.selectTree.filter(val);
			},
			/**
			 * tree 节点过滤
			 * @param {Object} value
			 * @param {Object} data
			 */
			filterNode(value, data) {
				if (!value) return true;
				return data.label.indexOf(value) !== -1;
			},
			/**
			 * tree 节点点击事件
			 * @param {Object} data
			 */
			selectTreeNodeClickEvent(data) {
				this.selectOptions = [data]; // 隐藏的 select option 赋值
				this.basic.selectModel = data.id; // select v-model 赋值
				this.$refs.select.blur(); // 收起 select 下拉框
			},
			/**
			 * 回显数据
			 */
			close(value) {
				this.dialogShow = value;
			},
			echoData() {
				// 隐藏的 select option 赋值
				this.selectOptions = [{
					id: data.id,
					label: data.label
				}];
				this.basic.selectModel = data.id; // select v-model 赋值
				this.defaultExpandedkeys = [data.id]; // 展开选中的节点
				this.$refs.selectTree.setCurrentKey(data.id); // 设置节点高亮
			},
			async fromSave() {
				try {
					let data = {
						material: this.material,
						situation: this.situation,
						emgSucce: this.emgSucce

					}
					let res = await this.$axios.post('entries/saveTempEntries', data);
					this.$message.success({
						message: this.$t('newText.msg105'),
						offset: 120
					})
				} catch (e) {
					//TODO handle the exception
				}
			},
			// 作品分类接口
			async entriesCate() {
				try {
					let res = await this.$axios.get('entries/cate');
					res.data.forEach((item, index) => {
						item.label = item.name;
						item.children = item.childlist;
						item.children.forEach((i, ix) => {
							i.label = i.name;
						})
					})
					this.entrList = res.data;
				} catch (e) {
					//TODO handle the exception
				}
			},
			async matchIndex() {
				try {
					let res = await this.$axios.get('match/index',{page:1});
					if (res.code == 1) {
						this.matchList = res.data[0].channel;
					}
				} catch (e) {

				}
			},
			changeLoc(key) {
				// 获取点击的按钮对应页面的id
				var PageId = document.querySelector('#page' + key)
				// 使用平滑属性，滑动到上方获取的距离
				// 下方我只设置了top，当然 你也可以加上 left 让他横向滑动
				// widow 根据浏览器滚动条，如果你是要在某个盒子里面产生滑动，记得修改
				window.scrollTo({
					'top': PageId.offsetTop,
					'behavior': 'smooth'
				})
			},
			// 赛道最终选择情况
			trackTap(item) {
				this.$refs.select.blur();
				this.material.en_theme_id = item.id;
				this.material.selectModel = this.matchList[this.matindex].en_channel_name + '-' + item.en_theme;
			},
			save() {
				if (this.schedule == 1) {
					if (!this.emgSucce) {
						this.$message.error({
							message: this.$t('newText.msg106'),
							offset: 120
						})
						return
					}
					if (this.material.en_copyright === 1) {
						if (!this.material.en_copyright_file) {
							this.$message.error({
								message: this.$t('newText.msg107'),
								offset: 120
							})
							return
						}
					}
					this.material.en_check_state = '0';
					this.addEntries();
				}
			},
			handleNodeClick(e) {
				if (e.childlist.length == 0) {
					this.material.className = e.label;
					this.$refs.classSlese.blur();
					this.material.en_category_id = e.id;
				}

			},
			nextStep() {
				if (this.schedule == 0) {
					this.$refs.basicFrom.validate(async valid => {
						if (valid) {
							if (!this.material.author_num) {
								this.$message.error({
									message: this.$t('newText.msg108'),
									offset: 120
								})
								return
							}
							if (this.material.en_tag.length == 0) {
								this.basic_hiden = true;
								return
							}
							for (var i = 0; i < this.material.author_num; i++) {
								if (!this.material.author[i]) {
                                    if(localStorage.getItem(languageSet)=='zh'){
										this.$message.error({
										message: `请填写${i+1}作者姓名～`,
										offset: 120
									});
									return
									}else{
										this.$message.error({
											message: `Please fill in${i+1}Author's name～`,
											offset: 120
										});
										return
									}
									
								}
							}
							this.$store.commit('setScheDule', this.schedule);
							this.schedule = 1;

						} else {
							if (this.material.en_tag.length == 0) {
								this.basic_hiden = true;
								return
							}
							// this.$message.error({message:'请填写完整的信息～',offset:120})
							// return false
						}
					})
					this.backTop();
					return
				}

				if (this.schedule == 1) {
					if (!this.emgSucce) {
						this.$message.error({
							message: this.$t('newText.msg110'),
							offset: 120
						})
						return
					}
					if (this.material.en_copyright == '-1') {
						this.$message.error({
							message: this.$t('newText.msg111'),
							offset: 120
						})
						return
					}
					if (this.material.en_copyright == 1) {
						if (!this.material.en_copyright_file) {
							this.$message.error({
								message: this.$t('newText.msg112'),
								offset: 120
							})
							return
						}
					}
					if (!this.download) {
						this.everyShow = true;
						return
					}
					this.material.en_check_state = '1';
					this.addEntries();

				}
				this.backTop();
				if (sessionStorage.getItem('forinForMationData')) {

				}
				// sessionStorage.setItem("forinForMationData", JSON.stringify(this.$data));
			},
			// 点击图片回到顶部方法，加计时器是为了过渡顺滑
			backTop() {
				const that = this
				let timer = setInterval(() => {
					let ispeed = Math.floor(-that.scrollTop / 5)
					document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
					if (that.scrollTop === 0) {
						clearInterval(timer)
					}
				}, 16)
			},

			// 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
			scrollToTop() {
				const that = this
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				that.scrollTop = scrollTop
				if (that.scrollTop > 60) {
					that.btnFlag = true
				} else {
					that.btnFlag = false
				}
			},
			lastStep() {
				this.schedule--;
				this.$store.commit('setScheDule', this.schedule);
				this.backTop();
				// if (sessionStorage.getItem('forinForMationData')) {
				// 	// let data = sessionStorage.getItem('forinForMationData');
				// 	// Object.assign(this.$data, JSON.parse(data));
				// }
			},
			cruxQue() {
				if (!this.keyWord) {
					this.$message({
						message: this.$t('newText.msg113'),
						type: 'warning',
						offset: 120
					});
					return
				}
				if (this.material.en_tag.length > 2) {
					this.$message({
						message: this.$t('newText.msg114'),
						type: 'warning',
						offset: 120
					});
					return
				}
				var show = false
				this.material.en_tag.forEach((item) => {
					if (item === this.keyWord) {
						this.$message({
							message: this.$t('newText.msg115'),
							type: 'warning',
							offset: 120
						});
						show = true
						return
					}
				})
				if (show) {
					return
				}
				this.material.en_tag.push(this.keyWord)
				this.close(false);
				this.keyWord = '';
				this.basic_hiden = false;
			},
			// 作品是否上市
			whetherClick(index) {
				this.material.en_situation = index;
			},
			// 是否版权登记过
			copyrightClick(index) {
				this.material.en_copyright = index;
			},
			bearAdd(item) {
				if (this.situation.length > 10) return;
				this.situation.push({
					en_name: '',
					en_prize: '',
					reward_time: ''
				})
			},
			bearDele(index) {
				this.situation.splice(index, 1);
			},
			getpptHttp(url, name) {
				this.material.en_explain.push({
					url,
					name
				});
			},
			getWordHttp(url, name) {
				this.material.en_modeling.push({
					url,
					name
				})
			},
			EmgSuccess(e) {
				this.emgSucce = e;
				this.material.en_images.unshift(e);
			},
			ImgSuccess(e) {
			
				this.material.en_images.push(e);
			},
			videoChange(e) {
				console.log(e)
				this.material.en_video = e;
			},
			getexplainImg(value) {
				this.material.en_copyright_file = value;
			},
			// 作品提交
			async addEntries() {
				try {
					let data = this.material;
					let en_modeling = [],
						en_explain = [];
					if (data.en_modeling.length > 0) {
						data.en_modeling.forEach((item, index) => {
							if (item.url) {
								en_modeling.push(item.url);
							} else if (item) {
								en_modeling.push(item);
							}
						})
					}
					if (data.en_explain.length > 0) {
						data.en_explain.forEach((item, index) => {
							if (item.url) {
								en_explain.push(item.url);
							} else if (item) {
								en_explain.push(item);
							}
						})
					}
					data.en_modeling = en_modeling;
					data.en_explain = en_explain;
					delete data.selectModel;
					delete data.className;
					data.situation = this.situation;
					data.situation.forEach((item) => {
						item.reward_time = parseInt(item.reward_time / 1000);
					})
					data.author_num.toString();
					data.en_check_state.toString();
					data.en_situation.toString();
					data.en_copyright.toString();
					data.en_copyright_file = data.en_copyright == 0 ? '' : data.en_copyright_file;
					let res = await this.$axios.post('entries/addEntries', this.material);
					if (res.code == 1) {
						this.schedule++;
						this.shopId = res.data.id
						sessionStorage.removeItem('forinForMationData');
						localStorage.setItem('download', this.download);
					}
				} catch (e) {
					console.log(e);
					//TODO handle the exception
				}
			},
			async entriesDetail(id) {
				try {
					let data = {
						id: id
					}
					let _this = this;
					let res = await this.$axios.post('entries/detail', data);
					if (res.code == 1) {
						let conten = res.data;
						if (conten.en_check_state == 0) {
							this.elrbtn = true;
						} else {
							this.elrbtn = false;
						}
						for (let key in conten) {
							for (let i in this.material) {
								if (key == i) {
									this.material[i] = conten[key];
								}
							}
						}
						this.material.en_explain = conten.en_explain[0] ? conten.en_explain : [];
						this.material.en_modeling = conten.en_modeling[0] ? conten.en_modeling : [];
						this.material.className = res.data.en_category_name;
						this.material.author = res.data.author_lsit;
						this.material.selectModel = res.data.en_track;
						this.emgSucce = res.data.en_images[0];
						this.situation = [];
						conten.situation.forEach((item) => {
							this.situation.push({
								en_name: item.en_name,
								en_prize: item.en_prize,
								reward_time: item.reward_time ? item.reward_time * 1000 : ''
							});
						})
					}

				} catch (e) {

					//TODO handle the exception
				}
			},
			classifyChange(e) {
				console.log(e)
			},
		}
	}
</script>
