<template>
  <div>
    <el-carousel ref="carousel"  :height="height" :initial-index="initial_index" :loop="loop" @change="elChange" :direction="direction" :type="type" :width="width" :arrow="arrow" :indicator-position="indicator_position" :autoplay="autoplays" :interval="interval"  :trigger="trigger" >
      <el-carousel-item  :height="height" v-for="(item,index) in carouselItem" :key="index" v-if="carouselItem">
          <div class="icon_image" v-if="matchType(item.src)=='image'">
              <img :src="item.src" :alt="item.title" :class="index==initial_index?'image_anmin':''">
          </div>
		  <div class="video_box">
			<video loop="loop" :src="item.src" :poster="postericon" class="video_conten" :autoplay="playStatus" width="100%" muted>
			</video>
		  </div>
      </el-carousel-item>
      <slot name="conten"></slot>
    </el-carousel>
  </div>
</template>
<script>
export default {
    props:{
		postericon:{
			type:[String],
			default:''
		},
        carouselItem:{
            type:[Number,Array,String,Object],
            default:''
        },
        height:{
            type:[String],
            default:''
        },
        width:{
            type:[String],
            default:'100%'
        },
        initial_index:{
            type:[Number],
            default:3
        },
        trigger:{
            type:[String],
            default:'click'
        },
        autoplays:{
            type:[Boolean],
            default:false
        },
        interval:{
            type:[Number],
            default:100000
        },
        indicator_position:{
            type:[String],
            default:'none'
        },
        arrow:{
            type:[String],
            default:'always'
        },
        type:{
            type:[String],
            default:''
        },
        loop:{
            type:[Boolean],
            default:true
        },
        direction:{
            type:[String],
            default:'horizontal'
        }
    },
	data(){
		return{
			autoplay: 'muted',
			muteStatus:'',
			playStatus:''
		}
	},
    watch:{
        initial_index(value){
            this.showstep(value);
        },
		carouselItem(value){
			value.forEach((item,index)=>{
				// console.log(this.matchType(item.src));
			})
		}
    },
    methods:{
        showstep(ind) {
            this.$refs.carousel.setActiveItem(ind)
        },
        elChange(e){
            this.$emit('elChange',e);
        },
		matchType(fileName) {
		        // 后缀获取
		        var suffix = '';
		        // 获取类型结果
		        var result = '';
		        try {
		          var flieArr = fileName.split('.');
		          suffix = flieArr[flieArr.length - 1];
		        } catch (err) {
		          suffix = '';
		        }
		        // fileName无后缀返回 false
		        if (!suffix) {
		          result = false;
		          return result;
		        }
		        // 图片格式
		        var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
		        // 进行图片匹配
		        result = imglist.some(function (item) {
		          return item == suffix;
		        });
		        if (result) {
		          result = 'image';
		          return result;
		        };
		        // 匹配txt
		        var txtlist = ['txt'];
		        result = txtlist.some(function (item) {
		          return item == suffix;
		        });
		        if (result) {
		          result = 'txt';
		          return result;
		        };
		        // 匹配 excel
		        var excelist = ['xls', 'xlsx'];
		        result = excelist.some(function (item) {
		          return item == suffix;
		        });
		        if (result) {
		          result = 'excel';
		          return result;
		        };
		        // 匹配 word
		        var wordlist = ['doc', 'docx'];
		        result = wordlist.some(function (item) {
		          return item == suffix;
		        });
		        if (result) {
		          result = 'word';
		          return result;
		        };
		        // 匹配 pdf
		        var pdflist = ['pdf'];
		        result = pdflist.some(function (item) {
		          return item == suffix;
		        });
		        if (result) {
		          result = 'pdf';
		          return result;
		        };
		        // 匹配 ppt
		        var pptlist = ['ppt'];
		        result = pptlist.some(function (item) {
		          return item == suffix;
		        });
		        if (result) {
		          result = 'ppt';
		          return result;
		        };
		        // 匹配 视频
		        var videolist = ['mp4', 'm2v', 'mkv'];
		        result = videolist.some(function (item) {
		          return item == suffix;
		        });
		        if (result) {
		          result = 'video';
		          return result;
		        };
		        // 匹配 音频
		        var radiolist = ['mp3', 'wav', 'wmv'];
		        result = radiolist.some(function (item) {
		          return item == suffix;
		        });
		        if (result) {
		          result = 'radio';
		          return result;
		        }
		        // 其他 文件类型
		        result = 'image';
		        return result;
		      }
    }
};
</script>
<style lang="less" scoped>
	.image_anmin{
		  animation-name: focus-in-contract;
		  animation-duration: 1s;
		  animation-timing-function: ease;
		  animation-delay: 0s;
		  animation-iteration-count: 1;
		  animation-direction: normal;
		  animation-fill-mode: none;
		@keyframes focus-in-contract {
		  0%{
		    letter-spacing: 1em;
		    filter: blur(12px);
		    opacity: 0;
		  }
		  100%{
		    filter: blur(0);
		    opacity: 1;
		  }
		}
	}
	.video_conten{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
 .icon_image{
     width: 100%;
     height: 100%;
     >img{
         width: 100%;
         height: 100%;
         object-fit:cover;
     }
 }
 .video_box{
	 width: 100%;
	 height: 100%;
	 position: relative;
 }
 /deep/ .el-carousel{
     overflow: hidden;
 }
</style>